import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

import { assetsFolders, assetsImport } from "../../helpers";

import "./style.css";

const Brands = props => {
  const { brands } = props;
  return (
    <div className="brands">
      <Row className="justify-content-center">
        {brands.map((item, i) => {
          let img;
          try {
            img = assetsImport(assetsFolders.img.brands, `${item.id}.svg`);
          } catch (ex) {
            try {
              img = assetsImport(assetsFolders.img.brands, `${item.id}.png`);
            } catch (ex) {}
          }
          return (
            img && (
              <Col key={i} className="brands-item">
                <Link to={`/productos?b=${item.id}`}>
                  <img src={img} alt={item.name} />
                </Link>
              </Col>
            )
          );
        })}
      </Row>
    </div>
  );
};

export default Brands;
