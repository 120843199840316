import React from "react";

import { configStyle } from "base";

import "./style.css";

const Loading = props => {
  const { min, grey } = props;
  return (
    <div className="loading">
      <div
        className={`spinner ${min ? "min" : ""} ${grey ? "grey" : ""}`}
        style={configStyle.secondary}
      />
    </div>
  );
};

export default Loading;
