import React from "react";
import { Row, Col } from "reactstrap";

import AutopartCard from "../AutopartCard";
import Pagination from "../../../components/Pagination";

const Autoparts = (props) => {
  const {
    isLoggedIn,
    isSeller,
    hidePagination,
    pag,
    rowsMax,
    resultCount,
    items,
    classes,
    cartAddItem,
  } = props;
  return (
    <div>
      {parseInt(resultCount, 0) === 0 ? (
        <Row>
          <Col>
            <h5 className="autoparts-message text-center">
              No se encontro ningun resultado.
            </h5>
          </Col>
        </Row>
      ) : (
        <Row>
          {items.map((item, i) => {
            return (
              <Col key={i} xs={12}>
                <AutopartCard
                  isLoggedIn={isLoggedIn}
                  isSeller={isSeller}
                  autopart={item}
                  cartAddItem={cartAddItem}
                />
              </Col>
            );
          })}
        </Row>
      )}
      {!hidePagination && (
        <Row>
          <Col>
            <Pagination
              pag={pag}
              rowsMax={rowsMax}
              total={resultCount}
              classes={classes}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Autoparts;
