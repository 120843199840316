import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

import {
  numberTypes,
  numberFormat,
  dateFormats,
  dateFormat,
  configSettings,
} from "base";

import { imageGet } from "../../../helpers";

import "./style.css";

class AutopartcardMin extends React.Component {
  render() {
    const {
      isLoading,
      hidePrice: hidePriceProp,
      isLoggedIn,
      isSeller,
      size,
      autopart,
    } = this.props;

    const hidePrice = !isLoggedIn || hidePriceProp;
    const { code, applications, products } = autopart;

    const { applicationName: name } =
      applications && applications.length ? applications[0] : {};

    const isPublic = true;
    const isOriginal = false;

    const { productPrice: price, productSales: sales } =
      products && products.length ? products[0] : {};

    const img = imageGet(code);

    const style = false ? {} : { backgroundImage: `url(${img})` };
    const link = `/productos/${code}`;

    return (
      <div className={`autopart-card-min ${size}`}>
        <Link to={link}>
          <div className="card">
            <Row noGutters>
              <Col>
                <div className="autopart-card-min-img" style={style} />
              </Col>
            </Row>
            <div className="autopart-card-min-body">
              <div
                className={`autopart-card-min-title ${
                  isLoading ? "loading" : ""
                }`}
              >
                {isLoading ? "" : name}
              </div>

              <div
                className={`autopart-card-min-detail ${
                  isLoading ? "loading" : ""
                }`}
              >
                {isLoading ? (
                  ""
                ) : (
                  <Row noGutters>
                    <Col xs="5" className="autopart-card-min-code">
                      <small>Cod</small> {code}
                    </Col>
                    {!hidePrice && (
                      <Col xs="7" className="autopart-card-min-price">
                        <small>{configSettings.currencySymbol}</small>{" "}
                        {numberFormat(numberTypes.PRICE, price)}{" "}
                        {configSettings.showPricePlusTax && (
                          <span className="autopart-card-min-plus-tax">
                            + IVA
                          </span>
                        )}
                      </Col>
                    )}
                  </Row>
                )}
              </div>
            </div>
            {!isLoading && (
              <div className="tags">
                {isSeller && !isPublic && (
                  <div className="tag inactive">Inactivo</div>
                )}
                {isOriginal && <div className="tag original">Original</div>}
                {isSeller && sales && sales.length > 0 && (
                  <div
                    className="tag sales"
                    title={dateFormat(
                      dateFormats.DD_MM_YYYY__hh_mm,
                      sales[0].saleDate
                    )}
                  >{`VA ${sales[0].saleQuant}`}</div>
                )}
              </div>
            )}
          </div>
        </Link>
      </div>
    );
  }
}

export default AutopartcardMin;
