import React from "react";
import { ObjectEdit as ObjectEditBase } from "base";
import Render from "./render";

class ModalObjectEdit extends React.Component {
  render() {
    return (
      <ObjectEditBase {...this.props}>
        <Render {...this.props} />
      </ObjectEditBase>
    );
  }
}
export default ModalObjectEdit;
