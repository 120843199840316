import { configId } from "base";
import robotec from "./robotec";
import nami from "./nami";
import namiHerramientas from "./namiHerramientas";
import namiElectrico from "./namiElectrico";
import namiClimatizadores from "./namiClimatizadores";

export default () => {
  switch (configId) {
    case "RTC":
      return robotec;
    case "NAM":
      return nami;
    case "NAH":
      return namiHerramientas;
    case "NAE":
      return namiElectrico;
    case "NAC":
      return namiClimatizadores;
    default:
      return [];
  }
};
