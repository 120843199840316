import React from "react";
import { Row, Col } from "reactstrap";
import Button from "@material-ui/core/Button";

import {
  dateFormats,
  dateFormat,
  orderStatesGet,
  orderStateGetColor,
  orderStateGetName,
  cartGetItemsCount,
  cartGetTotal
} from "base";

import CartTable from "../CartTable/render";

import "./style.css";

const orderStates = orderStatesGet();

const OrderCardInfoSection = props => {
  const { title, items } = props;
  return (
    <div className="order-card-info-section">
      <Row>
        <Col className="order-card-info-title">{title}</Col>
      </Row>
      {items.map(({ name, value }, i) => {
        return (
          <Row key={i}>
            {name && (
              <Col md="3" className="order-card-info-label">
                <label>{name}</label>
              </Col>
            )}
            <Col>{value}</Col>
          </Row>
        );
      })}
    </div>
  );
};

class OrderCard extends React.Component {
  state = { showDetail: false };

  toggleDetail = () => {
    this.setState({ showDetail: !this.state.showDetail });
  };

  render() {
    const { readOnly, windowSize, order, onChangeState } = this.props;
    const {
      address,
      addressCityName,
      addressStateName,
      date,
      id,
      items,
      number,
      paymentName,
      personLastName,
      personName,
      phone,
      shippingName,
      state,
      userEmail
    } = order;
    const { showDetail } = this.state;
    const fDate = dateFormat(dateFormats.D_MM_YYYY__hh_mm, date);
    return (
      <div className="card order-card">
        <div className="order-card-info">
          <Row className="order-card-info-top">
            <Col xs="4" className="order-card-date">
              {fDate}
            </Col>
            <Col xs="4" className="number text-center">{`#${number}`}</Col>
            <Col xs="4">
              <div className="text-right">
                {readOnly ? (
                  <div
                    className={`order-state min ${orderStateGetColor(state)}`}
                  >
                    {orderStateGetName(state)}
                  </div>
                ) : (
                  <select
                    id={id}
                    className={`custom-select order-state min ${orderStateGetColor(
                      state
                    )}`}
                    value={state}
                    onChange={onChangeState}
                  >
                    {orderStates.map((s, i) => {
                      return (
                        <option key={i} value={s.id} disabled={s.disabled}>
                          {s.name}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
            </Col>
          </Row>
          <div className="order-card-info-bottom">
            <Row>
              <Col xs="12" md="6">
                <OrderCardInfoSection
                  title="Datos personales"
                  items={[
                    {
                      name: "Nombre",
                      value: personName.trim() + " " + personLastName.trim()
                    },
                    { name: "Email", value: userEmail }
                  ]}
                />
                {showDetail && (
                  <div>
                    <OrderCardInfoSection
                      title="Teléfono"
                      items={[{ name: "Numero", value: phone }]}
                    />
                    <OrderCardInfoSection
                      title="Dirección"
                      items={[
                        { name: "Departamento", value: addressStateName },
                        { name: "Ciudad", value: addressCityName },
                        { name: "Dirección", value: address }
                      ]}
                    />
                  </div>
                )}
              </Col>
              <Col xs="12" md="6">
                <OrderCardInfoSection
                  title="Envio / Retiro"
                  items={[{ value: shippingName }]}
                />
                {showDetail && (
                  <div>
                    <OrderCardInfoSection
                      title="Metodo de pago"
                      items={[{ value: paymentName }]}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="text-center">
              <Button size="small" onClick={this.toggleDetail}>
                {showDetail ? "Menos" : "Ver mas"}
              </Button>
            </Col>
          </Row>
        </div>
        <CartTable
          showActions={false}
          showPrice={true}
          showTotal={true}
          windowSize={windowSize}
          itemsCount={cartGetItemsCount(items)}
          total={cartGetTotal(items)}
          items={items}
        />
      </div>
    );
  }
}

export default OrderCard;
