import React from "react";
import { Modal, ModalBody, ModalFooter, Container, Row, Col } from "reactstrap";
import {
  Button,
  TextField,
  Select,
  FormControlLabel,
  InputLabel
} from "@material-ui/core";

import Loading from "../Loading";

import "./style.css";
import ModalConfirmRemove from "../ModalConfirmRemove";
import { Switch } from "@material-ui/core";
import ModalImageSelect from "../ModalImageSelect";
import Icon from "../Icon";
import Actions from "../Actions";

const ModalObjectEdit = props => {
  const {
    isOpen,
    isLoading,
    isAdd,
    isList,
    isDefault,
    isDefaultNewState,
    isModalRemoveOpen,
    isModalImageSelectOpen,
    rounded,
    title,
    error,
    size,
    errors,
    parms,
    onClose,
    onInputChange,
    onSubmit,
    onRemove,
    onSelectImage,
    toggle
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      className={`modal-object-edit card ${rounded ? "rounded" : ""}`}
      size={size}
    >
      <div className="modal-top-title">{title}</div>
      <form noValidate onSubmit={onSubmit}>
        <ModalBody className="footer-error">
          {isLoading ? (
            <Loading />
          ) : (
            <Container>
              {parms
                .filter(p => !p.hidden)
                .map(({ disabled, type, name, label, value, options }, i) => (
                  <Row key={i}>
                    <Col xs="12" style={{ display: "table" }}>
                      {type === "select" && (
                        <div>
                          <div className="form-control select">
                            <InputLabel htmlFor="state">{label}</InputLabel>
                            <Select
                              disabled={disabled}
                              fullWidth
                              native
                              value={value}
                              className="select"
                              onChange={onInputChange}
                              inputProps={{
                                name,
                                id: name
                              }}
                            >
                              {options.map((o, i) => (
                                <option
                                  disabled={Boolean(o.disabled)}
                                  key={i}
                                  value={o.id}
                                >
                                  {o.name}
                                </option>
                              ))}
                            </Select>
                          </div>
                          <span className="form-control-error" />
                        </div>
                      )}
                      {(type === "text" ||
                        type === "number" ||
                        type === "textarea" ||
                        type === "password") && (
                        <div>
                          <TextField
                            disabled={disabled}
                            fullWidth
                            multiline={type === "textarea"}
                            error={Boolean(errors[name])}
                            type={type}
                            name={name}
                            className="form-control"
                            label={label}
                            value={value}
                            onChange={onInputChange}
                            style={{height: type === "textarea" ? 1000 : null}}
                          />
                          <span className="form-control-error">
                            {errors[name] && errors[name]}
                          </span>
                        </div>
                      )}
                      {type === "boolean" && (
                        <FormControlLabel
                          control={
                            <Switch
                              name={name}
                              value={name}
                              disabled={disabled}
                              checked={Boolean(value)}
                              onChange={(event, value) =>
                                onInputChange({ target: { name, value } })
                              }
                              color="primary"
                            />
                          }
                          label={label}
                        />
                      )}
                      {type === "image" && (
                        <FormControlLabel
                          label={label}
                          control={
                            value ? (
                              <div
                                className="img-item"
                                style={{
                                  backgroundImage: `url(${value.image})`
                                }}
                              >
                                <Actions onRemove={() => onSelectImage(null)} />
                              </div>
                            ) : (
                              <Button
                                className="img-item"
                                onClick={() => toggle("isModalImageSelectOpen")}
                              >
                                <Icon>plus</Icon>
                              </Button>
                            )
                          }
                        />
                      )}
                    </Col>
                  </Row>
                ))}
              {!isAdd && isList && (
                <Row>
                  <Col>
                    <div className="modal-object-edit-switch">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isDefaultNewState}
                            disabled={isDefault}
                            onChange={() => toggle("isDefaultNewState")}
                            color="primary"
                          />
                        }
                        label="Defecto"
                      />
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs="12">
                  <span className="form-control-error">{error}</span>
                </Col>
              </Row>
            </Container>
          )}
        </ModalBody>
        <ModalFooter>
          {!isAdd && isList && (
            <div className="modal-footer-left">
              <Button
                disabled={isLoading}
                onClick={() => toggle("isModalRemoveOpen")}
              >
                Eliminar
              </Button>
            </div>
          )}
          <div className="modal-footer-right">
            <Button onClick={onClose} disabled={isLoading}>
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={isLoading}>
              {isAdd ? "Agregar" : "Listo"}
            </Button>
          </div>
        </ModalFooter>
      </form>
      {isModalRemoveOpen && (
        <ModalConfirmRemove
          isLoading={isLoading}
          rounded={rounded}
          error={error}
          onCancel={() => toggle("isModalRemoveOpen")}
          onConfirm={onRemove}
        />
      )}
      {isModalImageSelectOpen && (
        <ModalImageSelect
          rounded={rounded}
          target="product"
          onClose={() => toggle("isModalImageSelectOpen")}
          onSelect={onSelectImage}
        />
      )}
    </Modal>
  );
};

export default ModalObjectEdit;

ModalObjectEdit.defaultProps = {
  size: "md"
};
