import React from "react";

import StockIcon from "../Icon/IconStock";

import "./style.css";

const Stock = props => {
  const { isLoading, isSeller, available, total } = props;
  return (
    <div className="stock">
      <StockIcon isLoading={isLoading} available={available} />
      {isSeller && !isLoading && <span className="stock-quant">{total}</span>}
    </div>
  );
};

export default Stock;
