import React from "react";
import MasterPage from "../MasterPage";
import ThreeD from "../../components/ThreeD";

class ThreeDPage extends React.Component {
  componentDidMount() {
    document.title = "3D";
  }

  render() {
    return (
      <MasterPage contentPaddingBottom={false}>
        <ThreeD />
      </MasterPage>
    );
  }
}
export default ThreeDPage;
