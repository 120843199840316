import React from "react";
import { Container, Row, Col } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, TextField } from "@material-ui/core";
import { configStyle, objectCompareById } from "base";
import ModalImageSelect from "../../components/ModalImageSelect";
import Icon from "../../components/Icon";
import Actions from "../../components/Actions";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  backgroundColor: isDragging
    ? configStyle.drag.dragging.backgroundColor
    : "transparent",
  ...draggableStyle
});

const Item = props => {
  const { item, onAdd, onRemoveType, onInputChange } = props;
  const { image, sd, color } = item;

  return (
    <Row className="justify-content-center">
      <Col xs="auto">
        {parseInt(image.id) === -1 ? (
          <Button className="banner-item" onClick={() => onAdd(item, "image")}>
            <Icon>plus</Icon>
          </Button>
        ) : (
          <div
            className="banner-item"
            style={{ backgroundImage: `url(${image.image})` }}
          >
            <Actions onRemove={() => onRemoveType(item.id, "image")} />
          </div>
        )}
      </Col>
      <Col xs="auto">
        {parseInt(sd.id) === -1 ? (
          <Button className="banner-item sd" onClick={() => onAdd(item, "sd")}>
            <Icon>plus</Icon>
          </Button>
        ) : (
          <div
            className="banner-item sd"
            style={{ backgroundImage: `url(${sd.image})` }}
          >
            <Actions onRemove={() => onRemoveType(item.id, "sd")} />
          </div>
        )}
      </Col>
      <Col xs="2">
        <TextField
          type="text"
          name="color"
          label="Color"
          value={color}
          className="banner-item-color"
          onChange={event => onInputChange(item.id, event)}
        />
      </Col>
    </Row>
  );
};

const INITIAL_STATE = {
  msiIsOpen: false,
  current: null,
  currentType: null
};

class ConfigBanner extends React.Component {
  state = INITIAL_STATE;

  onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const { id, items, update } = this.props;

    const itemsReorder = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    update(id, itemsReorder, true);
  };

  closeSelectImage = () => {
    this.setState(INITIAL_STATE);
  };

  onSelectImage = img => {
    const { id, items, update } = this.props;
    const { current, currentType } = this.state;
    const newItem = {
      ...current,
      [currentType]: {
        id: img.id,
        image: img.image
      }
    };

    const index = items.indexOf(current);
    if (index === -1) {
      const last = items.sort(objectCompareById)[items.length - 1];
      newItem.id = last ? last.id++ : 0;
      items.push(newItem);
    } else items[index] = newItem;

    update(id, items, true);
    this.closeSelectImage();
  };

  add = (item, type) => {
    this.setState({
      msiIsOpen: true,
      current: item,
      currentType: type
    });
  };

  onInputChange = (itemId, event) => {
    if (itemId !== -1) {
      const { name, value } = event.target;
      const { id, items, update } = this.props;
      const current = items.filter(i => i.id === itemId)[0];
      const index = items.indexOf(current);
      items[index][name] = value;
      update(id, items, true);
    }
  };

  onRemoveType = (itemId, type) => {
    const { id, update } = this.props;
    let { items } = this.props;
    const current = items.filter(i => i.id === itemId)[0];
    const index = items.indexOf(current);
    items[index][type] = { id: -1 };
    if (
      parseInt(items[index].image.id) === -1 &&
      parseInt(items[index].sd.id) === -1
    ) {
      // if two slots are empty => remove the object
      items = items.filter(i => i.id !== itemId);
    }

    update(id, items, true);
  };

  render() {
    const { msiIsOpen } = this.state;
    const { items: propsItems } = this.props;
    let items = [
      ...propsItems,
      {
        id: -1,
        image: { id: -1 },
        sd: { id: -1 }
      }
    ];
    return (
      <Container>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div ref={provided.innerRef}>
                {items.map((item, i) => {
                  return (
                    <Draggable
                      key={i}
                      draggableId={item.id.toString()}
                      index={i}
                      isDragDisabled={item.id === -1}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className="draggable"
                        >
                          <Item
                            key={i}
                            item={item}
                            onAdd={this.add}
                            onRemoveType={this.onRemoveType}
                            onInputChange={this.onInputChange}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {msiIsOpen && (
          <ModalImageSelect
            target="banner"
            onClose={this.closeSelectImage}
            onSelect={this.onSelectImage}
          />
        )}
      </Container>
    );
  }
}
export default ConfigBanner;
