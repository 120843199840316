import React from "react";
import { Filters } from "base";
import Render from "./render";

class ModalFiltersLists extends React.Component {
  render() {
    return (
      <Filters {...this.props}>
        <Render {...this.props} />
      </Filters>
    );
  }
}
export default ModalFiltersLists;
