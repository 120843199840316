import React from "react";
import { Link as LinkBase } from "react-router-dom";
import { Row, Col, Collapse } from "reactstrap";
import Radium from "radium";

import { configStyle, configSettings, arrayGroupBySize, configId } from "base";

import Divider from "../Divider";

import "./style.css";
import Icon from "../Icon";

const Link = Radium(LinkBase);

const SmartDeviceItem = (props) => {
  const { index, isOpen, item, toggleOpen } = props;
  const { id, name, items } = item;

  return (
    <Col className="categories-item">
      <div className="categories-item-title" onClick={() => toggleOpen(index)}>
        {items && items.length === 0 ? (
          <Link
            to={configSettings.enableCategoryLink ? `/productos?c=${id}` : ""}
          >
            <span>{name}</span>
          </Link>
        ) : (
          <span>{name}</span>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <ul>
          {items &&
            items.map((si, ii) => (
              <Link
                style={configStyle.menu.item}
                key={ii}
                to={`/productos?c=${si.id}`}
              >
                <li className="categories-item-subitem">{si.name}</li>
              </Link>
            ))}
        </ul>
      </Collapse>
      <Divider min fixWidth />
    </Col>
  );
};

const Item = (props) => {
  const { item, current, setCurrent } = props;
  const { id, name } = item;
  const active = current && current.id === id;
  return (
    <Col className="categories-item" onMouseOver={() => setCurrent(item)}>
      <Link to={configSettings.enableCategoryLink ? `/productos?c=${id}` : ""}>
        <div
          className={`categories-item-title ${active ? "active" : ""}`}
          style={
            active ? configStyle.categoryItem.active : configStyle.categoryItem
          }
        >
          <Row noGutters>
            <Col xs="11">{name}</Col>
            <Col xs="1" className="text-right">
              <Icon>chevron-right</Icon>
            </Col>
          </Row>
        </div>
      </Link>
    </Col>
  );
};

const SubItem = (props) => {
  const { subItem } = props;
  const { id, name } = subItem;

  return (
    <Col xs="12">
      <Link
        style={configStyle.menu.item}
        to={`/productos?c=${id}`}
        className="categories-item-subitem"
      >
        {name}
      </Link>
    </Col>
  );
};

class Categories extends React.Component {
  state = { current: null };

  toggleOpen = (index) => {
    const i = [`item${index}`];
    const { [i]: item } = this.state;
    this.setState({ [i]: item ? false : true });
  };

  setCurrent = (item) => this.setState({ current: item });

  render() {
    const { windowSize, categories } = this.props;
    const { current } = this.state;
    const smartDevice =
      windowSize !== "md" && windowSize !== "lg" && windowSize !== "xl";
    let subItemsGroups = [];
    if (!smartDevice && current) {
      subItemsGroups = arrayGroupBySize(
        current.items,
        current.items.length > categories.length
          ? current.items.length / 2
          : categories.length
      );
    }
    return (
      <div className="categories">
        <Row>
          <Col
            md="4"
            className={`categories-col ${
              configId === "NAM" ? "categories-col-nami" : ""
            }`}
          >
            {categories.map((item, i) =>
              smartDevice ? (
                <SmartDeviceItem
                  key={i}
                  index={i}
                  isOpen={this.state[`item${i}`]}
                  item={item}
                  toggleOpen={this.toggleOpen}
                />
              ) : (
                <Item
                  key={i}
                  item={item}
                  current={current}
                  setCurrent={this.setCurrent}
                />
              )
            )}
          </Col>
          <Col className="categories-subitems-col">
            {!smartDevice && (
              <Row>
                {subItemsGroups.map((items, sigi) => (
                  <Col key={sigi} md="6">
                    <Row>
                      {items.map((subItem, sii) => (
                        <SubItem
                          key={sii}
                          itemsCount={current.items.length}
                          subItem={subItem}
                        />
                      ))}
                    </Row>
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
export default Radium(Categories);
