import React from "react";
import ModalObjectEdit from "../ModalObjectEdit";

const ModalProductEdit = props => {
  const {
    isAddLoading,
    isAddSuccess,
    isUpdateLoading,
    isUpdateSuccess,
    isAdd,
    error,
    add,
    update,
    object,
    onSuccess,
    onSelectImage,
    setCurrent
  } = props;

  return (
    !object ? <React.Fragment/> : (
      <ModalObjectEdit
        isOpen={Boolean(object)}
        isLoading={isAdd ? isAddLoading : isUpdateLoading}
        isSuccess={isAdd ? isAddSuccess : isUpdateSuccess}
        title={`${isAdd ? "Agregar" : "Editar"} producto`}
        error={error}
        size="lg"
        object={object}
        submitFunc={isAdd ? add : update}
        onClose={() => setCurrent(null)}
        onSuccess={onSuccess}
        onSelectImage={onSelectImage}
      />
    )
  );
};

export default ModalProductEdit;
