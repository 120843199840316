import React from "react";
import { AutopartFilters as BaseAutopartFilters } from "base";
import Render from "./render";

class AutopartFilters extends React.Component {
  render() {
    return (
      <BaseAutopartFilters>
        <Render {...this.props} />
      </BaseAutopartFilters>
    );
  }
}
export default AutopartFilters;
