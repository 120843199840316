import React from "react";
import { Container, Row, Col } from "reactstrap";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { configStyle } from "base";

import Header from "../../components/Header";
import Snackbar from "../../components/Snackbar";
import Loading from "../../components/Loading";
import Icon from "../../components/Icon";

import "./style.css";

class UserRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: []
    };
  }

  addMessage = (type, message) => {
    let { messages } = this.state;
    messages.push(<Snackbar type={type} message={message} />);
    this.setState({ messages });
  };

  componentDidMount() {
    document.title = "Registro";
  }

  render() {
    const {
      isLoading,
      isSuccess,
      name,
      lastName,
      email,
      password,
      passwordConfirm,
      phone,
      stateId,
      cityId,
      address,
      errors,
      states,
      citiesFiltered,
      onInputChange,
      onSubmit
    } = this.props;
    const { messages } = this.state;

    return (
      <div
        className="user-page user-page-register"
        style={{
          background: `linear-gradient(0deg, transparent 50%, ${
            configStyle.primary.backgroundColor
          } 50%)`
        }}
      >
        <Header minimal transparent />
        <div className="user-page-content">
          <Container className="user-page-top">
            <Row>
              <Col xs="2">
                <IconButton onClick={() => window.history.back()}>
                  <Icon>arrow-left</Icon>
                </IconButton>
              </Col>
              <Col className="text-center">
                <h3>Completa tus datos</h3>
              </Col>
              <Col xs="2" />
            </Row>
          </Container>
          <div className="card">
            {isLoading ? (
              <Loading />
            ) : (
              <div>
                {isSuccess ? (
                  <Row>
                    <Col>
                      <div className="result text-center">
                        <Icon>check-circle</Icon>
                        <br />
                        ¡Registro realizado con éxito!
                        <br />
                        comprueba tu email para activar tu cuenta.
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <form noValidate onSubmit={onSubmit}>
                    <Row>
                      <Col xs="12" md="6">
                        <TextField
                          fullWidth
                          error={Boolean(errors.name)}
                          type="text"
                          name="name"
                          className="form-control"
                          label="Nombre"
                          value={name}
                          onChange={onInputChange}
                        />
                        <span className="form-control-error">
                          {errors.name && errors.name}
                        </span>
                      </Col>
                      <Col xs="12" md="6">
                        <TextField
                          fullWidth
                          error={Boolean(errors.lastName)}
                          type="text"
                          name="lastName"
                          className="form-control"
                          label="Apellido"
                          value={lastName}
                          onChange={onInputChange}
                        />
                        <span className="form-control-error">
                          {errors.lastName && errors.lastName}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6">
                        <TextField
                          fullWidth
                          error={Boolean(errors.email)}
                          type="email"
                          name="email"
                          className="form-control"
                          label="Email"
                          value={email}
                          onChange={onInputChange}
                        />
                        <span className="form-control-error">
                          {errors.email && errors.email}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6">
                        <TextField
                          fullWidth
                          error={Boolean(errors.password)}
                          type="password"
                          name="password"
                          className="form-control"
                          label="Contraseña"
                          value={password}
                          onChange={onInputChange}
                        />
                        <span className="form-control-error">
                          {errors.password && errors.password}
                        </span>
                      </Col>
                      <Col xs="12" md="6">
                        <TextField
                          fullWidth
                          error={Boolean(errors.passwordConfirm)}
                          type="password"
                          name="passwordConfirm"
                          className="form-control"
                          label="Confirme la contraseña"
                          value={passwordConfirm}
                          onChange={onInputChange}
                        />
                        <span className="form-control-error">
                          {errors.passwordConfirm && errors.passwordConfirm}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6">
                        <TextField
                          fullWidth
                          error={Boolean(errors.phone)}
                          type="text"
                          name="phone"
                          className="form-control"
                          label="Teléfono"
                          value={phone}
                          onChange={onInputChange}
                        />
                        <span className="form-control-error">
                          {errors.phone && errors.phone}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6">
                        <div className="form-control select">
                          <InputLabel htmlFor="stateId">
                            Departamento
                          </InputLabel>
                          <Select
                            fullWidth
                            native
                            value={stateId}
                            className="select"
                            onChange={onInputChange}
                            inputProps={{
                              name: "stateId",
                              id: "stateId"
                            }}
                          >
                            {states.map((s, i) => (
                              <option key={i} value={s.id}>
                                {s.name}
                              </option>
                            ))}
                          </Select>
                        </div>
                        <span className="form-control-error" />
                      </Col>
                      <Col xs="12" md="6">
                        <div className="form-control select">
                          <InputLabel htmlFor="cityId">Localidad</InputLabel>
                          <Select
                            fullWidth
                            native
                            value={cityId}
                            className="select"
                            onChange={onInputChange}
                            inputProps={{
                              name: "cityId",
                              id: "cityId"
                            }}
                          >
                            {citiesFiltered.map((c, i) => (
                              <option key={i} value={c.id}>
                                {c.name}
                              </option>
                            ))}
                          </Select>
                        </div>
                        <span className="form-control-error" />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6">
                        <TextField
                          fullWidth
                          error={Boolean(errors.address)}
                          type="text"
                          name="address"
                          className="form-control"
                          label="Dirección"
                          value={address}
                          onChange={onInputChange}
                        />
                        <span className="form-control-error">
                          {errors.address && errors.address}
                        </span>
                      </Col>
                    </Row>
                    <div className="register-actions">
                      <Button
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                      >
                        Crear cuenta
                      </Button>
                    </div>
                  </form>
                )}
              </div>
            )}
          </div>
        </div>
        {messages.map((message, i) => {
          return <div key={i}>{message}</div>;
        })}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.addError !== "" &&
      prevProps.addError !== this.props.addError
    ) {
      this.addMessage("error", this.props.addError);
    }
  }
}

export default UserRegister;
