import React from "react";
import { Container, Row, Col, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Button, Checkbox } from "@material-ui/core";

import { orderStateGet, orderStateGetColor, orderStateGetName } from "base";

import MasterPage from "../MasterPage";
import Loading from "../../components/Loading";
import Divider from "../../components/Divider";
import Orders from "../../components/Orders";

import "./style.css";

class ModalChangeState extends React.Component {
  state = {
    orderId: -1,
    sendEmail: false,
    newState: ""
  };

  toggleChangeEmail = event => {
    this.setState({ sendEmail: !this.state.sendEmail });
  };

  componentDidMount() {
    const { orderId, sendEmail, newState } = this.props;
    this.setState({ orderId, sendEmail, newState });
  }

  render() {
    const { isOpen, isLoading, close, changeState } = this.props;
    const { orderId, sendEmail, newState } = this.state;

    return (
      <Modal isOpen={isOpen} className="card">
        <ModalBody className="text-center">
          Esta seguro que desea cambiar el estado a:
          <div className={`order-state ${orderStateGetColor(newState)}`}>
            {orderStateGetName(newState)}
          </div>
          <Checkbox
            checked={sendEmail}
            color="primary"
            onChange={this.toggleChangeEmail}
          />
          Enviar email de notificación
        </ModalBody>
        <ModalFooter>
          <Button onClick={close} disabled={isLoading}>
            Cancelar
          </Button>
          <Button
            color="primary"
            disabled={isLoading}
            onClick={() => changeState(orderId, newState, sendEmail)}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

class OrdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalChangeStateOpen: false,
      currentOrderId: -1,
      currentOrderSendEmail: false,
      currentOrderNewState: "CAN"
    };
  }

  toggleModal = () => {
    this.setState({
      isModalChangeStateOpen: !this.state.isModalChangeStateOpen
    });
  };

  onChangeState = event => {
    const state = orderStateGet(event.target.value);

    this.setState({
      isModalChangeStateOpen: true,
      currentOrderId: event.target.id,
      currentOrderSendEmail: state.notSendEmail ? false : true,
      currentOrderNewState: state.id
    });
  };

  componentDidMount() {
    document.title = "Mis compras";
  }

  render() {
    const {
      ordersIsLoading,
      orderChangeStateIsPending,
      pag,
      rowsMax,
      resultCount,
      windowSize,
      filterError,
      company,
      orders,
      changeState
    } = this.props;
    const {
      isModalChangeStateOpen,
      currentOrderId,
      currentOrderSendEmail,
      currentOrderNewState
    } = this.state;
    return (
      <MasterPage>
        {ordersIsLoading ? (
          <Loading />
        ) : (
          <div>
            <Container>
              <Row>
                <Col>
                  <div className="title">Mis pedidos</div>
                </Col>
                <Col>
                  <div className="subtitle text-right">{`${resultCount} resultados`}</div>
                </Col>
              </Row>
            </Container>
            <Divider />
            <Container>
              <Row>
                <Col xs="12" md="12">
                  <Orders
                    readOnly={company ? false : true}
                    pag={pag}
                    rowsMax={rowsMax}
                    resultCount={resultCount}
                    error={filterError}
                    windowSize={windowSize}
                    orders={orders}
                    onChangeState={this.onChangeState}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        )}
        {isModalChangeStateOpen && (
          <ModalChangeState
            isOpen={isModalChangeStateOpen}
            isLoading={orderChangeStateIsPending}
            orderId={currentOrderId}
            sendEmail={currentOrderSendEmail}
            newState={currentOrderNewState}
            close={this.toggleModal}
            changeState={changeState}
          />
        )}
      </MasterPage>
    );
  }
}

export default OrdersPage;
