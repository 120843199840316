import React from "react";
import "./style.css";

const Divider = props => {
  const {
    min,
    marginTop,
    marginLeft,
    marginRight,
    marginBottom,
    fixWidth
  } = props;
  return (
    <div
      className={`divider ${min ? "min" : ""} ${marginTop ? "mt" : ""} ${
        marginLeft ? "ml" : ""
      } ${marginRight ? "mr" : ""} ${marginBottom ? "mb" : ""} ${
        fixWidth ? "fix" : ""
      }`}
    />
  );
};

Divider.defaultProps = {
  marginBottom: true
};

export default Divider;
