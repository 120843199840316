import React from "react";
import Icon from "../../components/Icon";

import "./style.css";

const Actions = props => {
  const { onRemove } = props;
  return (
    <div className="actions">
      <button onClick={onRemove}>
        <Icon>trash</Icon>
      </button>
    </div>
  );
};
export default Actions;
