import React from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Icon from "../../Icon";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Loading from "../../../components/Loading";

const columns = [
  // {
  //   id: "code",
  //   name: "code",
  // },
  {
    id: "name",
    name: "Modelo",
  },
  {
    id: "engine",
    name: "Motor",
  },
  // {
  //   id: "chassis",
  //   name: "chassis",
  // },
  {
    id: "year",
    name: "Año",
  },
];

const gridItemStyle = {};

const itemStyle = {
  display: "flex",
  flexDirection: "column",
  height: "50vh",
  maxHeight: "50vh",
  border: "1px solid #c6c6c6",
  borderRadius: 8,
  backgroundColor: "#f6f6f6",
};

const inputStyle = {
  padding: "12px 8px 0 8px",
};

class AutopartFilters extends React.Component {
  render() {
    const {
      vehiclesIsLoading,
      brands,
      categories,
      vehicles,
      filterCategory,
      filterSubCategory,
      filterBrand,
      filterVehicle,
      selectedCategory,
      selectedSubCategory,
      selectedBrand,
      setCategory,
      setSubCategory,
      setBrand,
      handleFilterCategoryChange,
      handleFilterSubCategoryChange,
      handleFilterBrandChange,
      handleFilterVehicleChange,
      history,
    } = this.props;

    const filteredCategories = categories.filter((category) =>
      category.name.toLowerCase().includes(filterCategory.toLowerCase())
    );

    const filteredSubCategories =
      selectedCategory?.items.filter((subCategory) =>
        subCategory.name.toLowerCase().includes(filterSubCategory.toLowerCase())
      ) || [];

    const filteredBrands = brands.filter((brand) =>
      brand.name.toLowerCase().includes(filterBrand.toLowerCase())
    );

    const filteredVehicles = vehicles?.filter((vehicle) => {
      return vehicle.name.toLowerCase().includes(filterVehicle.toLowerCase());
    });

    return (
      <>
        <Grid container spacing={8} style={{ padding: "0 8px" }}>
          <Grid item xs={12} sm={4} style={gridItemStyle}>
            <div style={itemStyle}>
              <div style={inputStyle}>
                <TextField
                  fullWidth
                  type="text"
                  value={filterCategory}
                  onChange={handleFilterCategoryChange}
                  label="Categoría"
                  placeholder="Sistema de enfriamiento"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      backgroundColor: "#f9f9f9",
                      padding: "12px 16px",
                    },
                  }}
                  style={{
                    marginBottom: 8,
                  }}
                />
              </div>
              <List dense style={{ overflow: "auto" }}>
                {filteredCategories.map((category, i) => {
                  return (
                    <ListItem
                      key={i}
                      button
                      selected={category.id === selectedCategory?.id}
                      onClick={() => setCategory(category)}
                    >
                      <ListItemText primary={category.name} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" style={{ fontSize: 12 }}>
                          <Icon>chevron-right</Icon>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} style={gridItemStyle}>
            <div style={itemStyle}>
              <div style={inputStyle}>
                <TextField
                  fullWidth
                  type="text"
                  value={filterSubCategory}
                  onChange={handleFilterSubCategoryChange}
                  label="Sub categoría"
                  placeholder="Radiador"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      backgroundColor: "#f9f9f9",
                      padding: "12px 16px",
                    },
                  }}
                  style={{
                    marginBottom: 8,
                  }}
                />
              </div>
              {selectedCategory && (
                <List dense style={{ overflow: "auto" }}>
                  {filteredSubCategories.map((subCategory, i) => {
                    return (
                      <ListItem
                        key={i}
                        button
                        selected={subCategory.id === selectedSubCategory?.id}
                        onClick={() => setSubCategory(subCategory)}
                      >
                        <ListItemText primary={subCategory.name} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" style={{ fontSize: 12 }}>
                            <Icon>chevron-right</Icon>
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={4} style={gridItemStyle}>
            <div style={itemStyle}>
              <div style={inputStyle}>
                <TextField
                  fullWidth
                  type="text"
                  value={filterBrand}
                  onChange={handleFilterBrandChange}
                  label="Marca"
                  placeholder="Toyota"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      backgroundColor: "#f9f9f9",
                      padding: "12px 16px",
                    },
                  }}
                  style={{
                    marginBottom: 8,
                  }}
                />
              </div>
              {console.log("filteredBrands", filteredBrands, selectedBrand)}
              {selectedSubCategory && (
                <List dense style={{ overflow: "auto" }}>
                  {filteredBrands.map((brand, i) => {
                    return (
                      <ListItem
                        key={i}
                        button
                        selected={
                          brand.id.toString() === selectedBrand?.id.toString()
                        }
                        onClick={() => setBrand(brand)}
                      >
                        <ListItemText primary={brand.name} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" style={{ fontSize: 12 }}>
                            <Icon>chevron-right</Icon>
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </div>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          {(vehiclesIsLoading || filteredVehicles) && (
            <Grid item style={{ width: "100%" }}>
              <div style={inputStyle}>
                <TextField
                  fullWidth
                  type="text"
                  value={filterVehicle}
                  onChange={handleFilterVehicleChange}
                  label="Modelo"
                  placeholder="Hilux 3.0"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      backgroundColor: "#f9f9f9",
                      padding: "12px 16px",
                    },
                  }}
                  style={{
                    marginBottom: 8,
                  }}
                />
              </div>
              <br />
              {vehiclesIsLoading ? (
                <Loading />
              ) : filteredVehicles?.length === 0 ? (
                <div style={{ textAlign: "center" }}>
                  No se encontraron resultados.
                </div>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((c, i) => (
                        <TableCell key={i}>{c.name}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredVehicles?.map((vehicle, i) => (
                      <TableRow
                        key={i}
                        hover
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push(
                            `/productos?c=${selectedSubCategory.id}&v=${vehicle.id}`
                          );
                        }}
                      >
                        {/* <TableCell>{vehicle.code}</TableCell> */}
                        <TableCell>{vehicle.name}</TableCell>
                        <TableCell>{vehicle.engine}</TableCell>
                        {/* <TableCell>{vehicle.chassis}</TableCell> */}
                        <TableCell>{vehicle.year}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}

export default AutopartFilters;
