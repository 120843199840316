import React from "react";
import { Newsletter as BaseNewsletter } from "base";
import Render from "./render";

class Newsletter extends React.Component {
  render() {
    return (
      <BaseNewsletter>
        <Render {...this.props} />
      </BaseNewsletter>
    );
  }
}
export default Newsletter;
