import React from "react";
import { Relases as BaseRelases } from "base";
import Render from "./render";

class Relases extends React.Component {
  render() {
    return (
      <BaseRelases>
        <Render />
      </BaseRelases>
    );
  }
}
export default Relases;
