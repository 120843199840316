import React from "react";

import { configId, configName } from "base";

import MasterPage from "../MasterPage";
import AutopartRelases from "../../components/nami/AutopartRelases";
import Relases from "../../components/Relases";
import Banner from "../../components/Banner";

import "./style.css";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };
  }

  componentDidMount() {
    document.title = configName;
  }

  render() {
    return (
      <MasterPage
        contentPaddingTop={false}
        headerProps={{ absolute: true, transparent: true }}
      >
        <div className="home">
          <Banner />
          {configId === "NAM" ? <AutopartRelases /> : <Relases />}
        </div>
      </MasterPage>
    );
  }
}

export default HomePage;
