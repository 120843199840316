import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Button from "@material-ui/core/Button";

import {
  numberTypes,
  numberFormat,
  dateFormats,
  dateFormat,
  configSettings,
} from "base";

import { imageGet } from "../../helpers";

import Icon from "../Icon";

import "./style.css";
import ProductStocks from "../ProductStocks";

class ProductCard extends React.Component {
  render() {
    const {
      hidePrice: hidePriceProp,
      hideActions,
      isSeller,
      enableBuy,
      size,
      product,
      cartAddItem,
      setCurrent,
    } = this.props;

    const hidePrice = !configSettings.enableSale || hidePriceProp;

    const {
      isLoading,
      isPublic,
      isOriginal,
      code,
      family,
      name,
      price,
      images,
      sales,
    } = product;
    const img = imageGet(family ? family : code, images);

    const style = false ? {} : { backgroundImage: `url(${img})` };
    const link = `/productos/${family ? family : code}`;
    return (
      <div className={`product-card ${size}`}>
        {isSeller && (
          <div className="product-admin-actions absolute text-right">
            <button onClick={() => setCurrent(product)}>
              Editar<Icon>edit</Icon>
            </button>
          </div>
        )}
        <Link to={link}>
          <div className="card">
            <Row noGutters>
              <Col>
                <div className="product-card-img" style={style} />
              </Col>
            </Row>
            <div className="product-card-body">
              <div
                className={`product-card-title ${isLoading ? "loading" : ""}`}
              >
                {isLoading ? "" : name}
              </div>

              {!hidePrice && (
                <div
                  className={`product-card-detail ${
                    isLoading ? "loading" : ""
                  }`}
                >
                  {isLoading ? (
                    ""
                  ) : (
                    <Row noGutters>
                      <Col xs="5" className="product-card-code">
                        <small>Cod</small> {code}
                      </Col>
                      <Col xs="7" className="product-card-price">
                        <small>{configSettings.currencySymbol}</small>{" "}
                        {numberFormat(numberTypes.PRICE, price)}{" "}
                        {configSettings.showPricePlusTax && (
                          <span className="product-card-plus-tax">+ IVA</span>
                        )}
                      </Col>
                    </Row>
                  )}
                </div>
              )}
              {!hideActions && (
                <Row className="product-card-actions">
                  <Col xs="auto">
                    <div className="product-card-stock">
                      <ProductStocks
                        isSeller={isSeller}
                        label="Stock"
                        products={[product]}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
            {!isLoading && (
              <div className="tags">
                {isSeller && !isPublic && (
                  <div className="tag inactive">Inactivo</div>
                )}
                {isOriginal && <div className="tag original">Original</div>}
                {isSeller && sales && sales.length > 0 && (
                  <div
                    className="tag sales"
                    title={dateFormat(
                      dateFormats.DD_MM_YYYY__hh_mm,
                      sales[0].saleDate
                    )}
                  >{`VA ${sales[0].saleQuant}`}</div>
                )}
              </div>
            )}
          </div>
        </Link>
        {!hideActions && (
          <Row className="product-card-actions">
            <Col />
            {configSettings.enableSale && (
              <Col
                className={`text-right btn-add ${!enableBuy ? "disabled" : ""}`}
              >
                <Button
                  size="small"
                  disabled={!enableBuy}
                  onClick={(event) => {
                    event.stopPropagation();
                    cartAddItem(product);
                  }}
                >
                  Comprar <Icon>shopping-bag</Icon>
                </Button>
              </Col>
            )}
          </Row>
        )}
      </div>
    );
  }
}

export default withRouter(ProductCard);
