import React from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { Row, Col } from "reactstrap";

import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Dialog from "@material-ui/core/Dialog";

import { productOrderOptionsGet, objectCompareByName, configSettings } from "base";

import Icon from "../Icon";

import "./style.css";

const productOrderOptions = productOrderOptionsGet();

class Filters extends React.Component {
  state = {
    dialogOrder: false,
    dialogFilters: false
  };

  toggleDialogOrder = () =>
    this.setState({ dialogOrder: !this.state.dialogOrder });

  toggleDialogFilters = () =>
    this.setState({ dialogFilters: !this.state.dialogFilters });

  getLink = hideQuery => {
    const { location } = this.props;
    const { search } = location;
    const query = queryString.parse(search);
    // delete pag and category
    delete query["pag"];
    delete query[hideQuery];
    // const and = Object.keys(query).length === 0 ? "" : "&";
    return "?" + queryString.stringify(query); // }${and}`;
  };

  render() {
    const { orderBy, filters } = this.props;
    const { dialogOrder, dialogFilters } = this.state;
    const linkOrder = this.getLink("order");
    const filtersContent = filters
      .filter(f => f.visible)
      .map((f, fi) => (
        f.current &&
        <div key={fi} className="filter-option">
          <div className="filter-option-title">{f.title}</div>
          {f.current && f.current.parent && (
            <Row>
              <Col>
                <Chip
                  label={f.current.parent.name}
                  className="filter-option-chip"
                  onDelete={!configSettings.enableEditFilters ? null : () =>
                    this.props.history.push(this.getLink(f.query))
                  }
                />
              </Col>
            </Row>
          )}
          {f.current && (
            <Row>
              <Col>
                <Chip
                  label={f.current.title}
                  className="filter-option-chip"
                  onDelete={!configSettings.enableEditFilters ? null : () =>
                    this.props.history.push(
                      f.current.parent
                        ? this.getLink(f.query) +
                            "&" +
                            f.query +
                            "=" +
                            f.current.parent.value
                        : this.getLink(f.query)
                    )
                  }
                />
              </Col>
            </Row>
          )}
          <ul className="filter-ul">
            {f.items
              .filter(i => !f.current || i.value !== f.current.value)
              .sort(objectCompareByName)
              .map((fi, ii) => (
                <li key={ii}>
                  <Link
                    to={this.getLink(f.query) + "&" + f.query + "=" + fi.value}
                  >
                    {fi.name}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      ));

    return (
      <div className="filters">
        <div className="d-none d-lg-block filters-actions">
          {
            configSettings.showProductSort &&
            <Row>
              <Col className="filter-option">
                <div className="filter-option-title">ORDEN</div>
                <div className="form-control select">
                  <Select
                    fullWidth
                    native
                    value={orderBy}
                    className="select"
                    onChange={event =>
                      this.props.history.push(
                        linkOrder + "&order=" + event.target.value.toLowerCase()
                      )
                    }
                    inputProps={{ name: "state" }}
                  >
                    {productOrderOptions.map((s, i) => (
                      <option key={i} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>
          }
          <Row>
            <Col>{filtersContent}</Col>
          </Row>
        </div>
        <div className="d-block d-lg-none filters-actions">
          <Row>
            {
              configSettings.showProductSort && 
              <Col xs="auto" className="text-center">
                <Button
                  size="small"
                  className="filter-option-title"
                  onClick={this.toggleDialogOrder}
                >
                  <Icon>sort</Icon>
                  Orden
                </Button>
              </Col>
            }
            {
              configSettings.enableEditFilters && 
              <Col xs="auto" className="text-center">
                <Button
                  size="small"
                  className="filter-option-title"
                  onClick={this.toggleDialogFilters}
                >
                  <Icon>filter</Icon>
                  Filtros
                </Button>
              </Col>
            }
          </Row>
        </div>
        <Dialog
          open={dialogOrder}
          onClose={this.toggleDialogOrder}
          className="filters-dialog"
        >
          <div className="filters-dialog-content">
            <div className="filters-dialog-title">Orden</div>
            <List>
              {productOrderOptions.map((s, i) => (
                <ListItem
                  key={i}
                  button
                  disableGutters
                  className="filters-dialog-item"
                  onClick={() =>
                    this.props.history.push(
                      linkOrder + "&order=" + s.id.toLowerCase()
                    )
                  }
                >
                  <Col xs="2">{orderBy === s.id && <Icon>check</Icon>}</Col>
                  <Col>{s.name}</Col>
                </ListItem>
              ))}
            </List>
          </div>
        </Dialog>
        <Dialog
          open={dialogFilters}
          onClose={this.toggleDialogFilters}
          className="filters-dialog"
        >
          <div className="filters-dialog-content">
            <div className="filters-dialog-title">Filtros</div>
            {filtersContent}
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Filters;
