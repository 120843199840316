import React from "react";
import Swiper from "react-id-swiper";
import BackgroundImageOnLoad from "background-image-on-load";

import { configImgBtnCarousel, configSettings } from "base";

import "react-id-swiper/src/styles/css/swiper.css";
import "./style.css";

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.go = false;
    this.swiper = null;
    this.swiperParms = {
      loop: true,
      centeredSlides: true,
      // speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true
      },
      on: {
        // loop fix
        progress: () => {
          if (
            this.swiper &&
            this.swiper.realIndex === this.props.items.length - 1 &&
            this.swiper.progress === 1
          ) {
            setTimeout(() => this.swiper.slideToLoop(0), 100);
          }
        }
      }
    };
    this.state = {
      bgFirstIsLoaded: false
    };
    if (configImgBtnCarousel.prev) {
      this.swiperParms.renderPrevButton = () => (
        <button
          className="swiper-button-prev"
          style={{ backgroundImage: `url(${configImgBtnCarousel.prev})` }}
        />
      );
    }
    if (configImgBtnCarousel.next) {
      this.swiperParms.renderNextButton = () => (
        <button
          className="swiper-button-next"
          style={{ backgroundImage: `url(${configImgBtnCarousel.next})` }}
        />
      );
    }
  }

  render() {
    const { windowSize, items } = this.props;
    const { bgFirstIsLoaded } = this.state;

    const firstImg =
      items.length === 0
        ? ""
        : parseInt(items[0].sd.id) !== -1 &&
          (windowSize === "xs" || windowSize === "sm")
          ? items[0].sd.image
          : items[0].image.image;

    const firstColor = items.length === 0 ? "" : items[0].color;
    const bannerItemClass = `banner-item ${configSettings.mobileSquare ? 'mb-square' : ''}`;
    
    return (
      <div className="banner">
        {!bgFirstIsLoaded ? (
          <div className={bannerItemClass} style={{ backgroundColor: firstColor }}>
            <BackgroundImageOnLoad
              src={firstImg}
              onLoadBg={() => this.setState({ bgFirstIsLoaded: true })}
            />
          </div>
        ) : (
          <Swiper
            ref={node => {
              if (node) this.swiper = node.swiper;
            }}
            {...this.swiperParms}
          >
            {items.map(({ color, sd, image, link }, i) => {
              const imgURL =
                parseInt(sd.id) !== -1 &&
                  (windowSize === "xs" || windowSize === "sm")
                  ? sd.image
                  : image.image;
              const img = (<div
                  key={i}
                  className={bannerItemClass}
                  style={{
                    backgroundColor: color,
                    backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0) 88%, rgba(0,0,0,.5) 100%), url(${imgURL})`
                  }}
                />);

              return link ? <a href={link} target="_blank" rel="noopener noreferrer">{img}</a> : img;
            })}
          </Swiper>
        )}
      </div>
    );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.items !== this.props.items && this.swiper) {
      this.swiper.update();
    }
  }
}

export default Banner;
