import React from "react";
import { CartTable as BaseCartTable } from "base";
import Render from "./render";

class CartTable extends React.Component {
  render() {
    return (
      <BaseCartTable>
        <Render {...this.props} />
      </BaseCartTable>
    );
  }
}
export default CartTable;
