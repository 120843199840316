import React from "react";
import { Row, Col, Modal, ModalBody, ModalFooter } from "reactstrap";
import { FormControlLabel, Switch, Button } from "@material-ui/core";
import Loading from "../Loading";

class ModalFiltersLists extends React.Component {
  getValuesItems = type => {
    const filtered = this.props.values.filter(v => v.type === type);
    return filtered.length > 0 ? filtered[0].items : [];
  };

  render() {
    const {
      rounded,
      categoriesLoaded,
      companyFiltersLoaded,
      tagsLoaded,
      workBranchsLoaded,
      filters,
      setFilterArray,
      onClose,
      onConfirm
    } = this.props;

    return (
      <Modal
        isOpen={true}
        className={`card ${rounded ? "rounded" : ""} modal-filters-lists`}
        size="xl"
      >
        <div className="modal-top-title">Filtros</div>
        <ModalBody>
          {!(
            categoriesLoaded &&
            companyFiltersLoaded &&
            tagsLoaded &&
            workBranchsLoaded
          ) ? (
            <Loading />
          ) : (
            <Row>
              {filters
                .filter(f => f.visible)
                .map((f, fi) => (
                  <Col key={fi}>
                    {f.title}
                    {f.items.map((i, ii) => {
                      const checked =
                        this.getValuesItems(f.type).filter(v => v.id === i.id)
                          .length > 0;
                      return (
                        <Row key={ii}>
                          <Col>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={checked}
                                  color="primary"
                                  className="modal-filters-lists-switch"
                                  onChange={(object, checked) =>
                                    setFilterArray(f.type, checked, i)
                                  }
                                />
                              }
                              label={i.name}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                ))}
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer-right">
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit" color="primary" onClick={onConfirm}>
              Confirmar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalFiltersLists;
