// base
import {
  componentWithBase,
  requireAuth,
  AutopartDetailPage as BaseAutopartDetailPage,
  AutopartsPage as BaseAutopartsPage,
  OrdersPage as BaseOrdersPage,
  CartPage as BaseCartPage,
  ContactPage as BaseContactPage,
  ConfigPage as BaseConfigPage,
  // ProductsPage as BaseProductsPage,
  // ProductDetailPage as BaseProductDetailPage,
  UserActivationPage as BaseUserActivationPage,
  UserRestorePwd as BaseUserRestorePwd,
  UserRegisterPage as BaseUserRegisterPage,
  UserProfilePage as BaseUserProfilePage,
  UserLoginPage as BaseUserLoginPage,
} from "base";
// views
import AutopartDetailPage from "../views/nami/AutopartDetailPage";
import AutopartsPage from "../views/nami/AutopartsPage";
import OrdersPage from "../views/OrdersPage";
import CartPage from "../views/CartPage";
import ContactPage from "../views/ContactPage";
import ConfigPage from "../views/ConfigPage";
import HomePage from "../views/HomePage";
import CompanyPage from "../views/nami/CompanyPage";
import ServicesPage from "../views/nami/ServicesPage";
import NotFoundPage from "../views/NotFoundPage";
// import ProductsPage from "../views/ProductsPage";
// import ProductDetailPage from "../views/ProductDetailPage";
// import PrintPage from "../views/PrintPage";
// import TestPage from "../views/TestPage";
import UserActivationPage from "../views/UserActivationPage";
import UserLoginPage from "../views/UserLoginPage";
import UserProfilePage from "../views/UserProfilePage";
import UserRegisterPage from "../views/UserRegisterPage";
import UserRestorePwd from "../views/UserRestorePwd";

export default [
  {
    exact: true,
    path: "/",
    component: HomePage,
  },
  // Base
  {
    path: "/registro",
    component: componentWithBase(BaseUserRegisterPage, UserRegisterPage),
  },
  {
    path: "/iniciar",
    component: componentWithBase(BaseUserLoginPage, UserLoginPage),
  },
  {
    path: "/activar/:guid",
    component: componentWithBase(BaseUserActivationPage, UserActivationPage),
  },
  {
    exact: true,
    path: "/restablecer-contrasena",
    component: componentWithBase(BaseUserRestorePwd, UserRestorePwd),
  },
  {
    path: "/restablecer-contrasena/:email/:key",
    component: componentWithBase(BaseUserRestorePwd, UserRestorePwd),
  },
  {
    path: "/empresa",
    component: CompanyPage,
  },
  {
    path: "/servicios",
    component: ServicesPage,
  },
  {
    exact: true,
    path: "/productos",
    component: componentWithBase(BaseAutopartsPage, AutopartsPage),
  },
  {
    path: "/productos/:code",
    component: componentWithBase(BaseAutopartDetailPage, AutopartDetailPage),
  },
  {
    path: "/mi-compra",
    component: componentWithBase(BaseCartPage, CartPage),
  },
  {
    path: "/contacto",
    component: componentWithBase(BaseContactPage, ContactPage),
  },
  // Auth
  // {
  //   path: "/test",
  //   component: requireAuth(TestPage)
  // },
  // Auth & Base
  {
    path: "/perfil",
    component: componentWithBase(
      BaseUserProfilePage,
      requireAuth(UserProfilePage)
    ),
  },
  {
    path: "/mis-compras",
    component: componentWithBase(BaseOrdersPage, requireAuth(OrdersPage)),
  },
  // Auth & Base & Role: Seller
  {
    path: "/:companyId/pedidos",
    component: componentWithBase(BaseOrdersPage, requireAuth(OrdersPage, true)),
  },
  {
    path: "/:companyId/config",
    component: componentWithBase(BaseConfigPage, requireAuth(ConfigPage, true)),
  },
  // 404
  {
    path: "/404",
    component: NotFoundPage,
  },
  {
    component: NotFoundPage,
  },
];
