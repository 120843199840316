import React from "react";
import { Row, Col } from "reactstrap";

import Button from "@material-ui/core/Button";

import { numberFormat, numberTypes, configSettings } from "base";

import "./style.css";

import Stock from "../Stock";
import Icon from "../Icon";

const ProductStocks = ({
  isLoggedIn,
  isSeller,
  products,
  stocks,
  cartAddItem,
  enableSale = false,
  label,
}) => {
  return (
    <div className="product-stocks">
      <Row>
        {products.map((product) => {
          const { code, price } = product;
          const { available, branches = [] } =
            stocks.find((s) => s.code === code) || {};
          const stockBranches = branches.map(({ code, quant }) => ({
            code: code.substring(0, 1),
            quant,
            style: { opacity: quant == 0 ? 0.3 : 1 },
          }));
          return (
            <Col key={code} className="product-stock-col">
              {isLoggedIn && (
                <>
                  <span className="product-price">
                    <small>$</small> {numberFormat(numberTypes.PRICE, price)}
                  </span>
                  <br />
                </>
              )}
              <small>Cod</small> {code}
              <span className="product-detail-stock">
                {isSeller && stockBranches.length > 0 ? (
                  <div>
                    {label}
                    <table>
                      <tr>
                        {stockBranches.map(({ code, style }) => (
                          <th style={style}>{code}</th>
                        ))}
                      </tr>
                      <tr>
                        {stockBranches.map(({ quant, style }) => (
                          <td style={style}>{quant}</td>
                        ))}
                      </tr>
                    </table>
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    {label}{" "}
                    <Stock
                      isLoading={available === undefined}
                      isSeller={isSeller}
                      available={available}
                    />
                  </div>
                )}
              </span>
              {enableSale && configSettings.enableSale && !isSeller && (
                <Button
                  fullWidth
                  disabled={
                    !!!available || !!(available === "NO" && cartAddItem)
                  }
                  variant="contained"
                  size="small"
                  color="primary"
                  className="product-detail-button"
                  onClick={() => cartAddItem(product)}
                >
                  Comprar <Icon>shopping-bag</Icon>
                </Button>
              )}
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default ProductStocks;
