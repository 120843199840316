import React from "react";
import { Container, Row, Col } from "reactstrap";
import { configContacts, numbersOnly } from "base";
import Divider from "../../Divider";
import Icon from "../../Icon";

import "./style.css";

const Contacts = props => {
  const { extended } = props;
  return (
    <div className="contacts">
      {!extended ? (
        <Container>
          <Row>
            {configContacts.filter(c => !c.hideMin).map(({ brand, icon, link }, i) => (
              <Col key={i} className={`contact-${icon}`}>
                <a href={link} rel="noopener noreferrer" target="_blank">
                  <Icon brand={brand}>{icon}</Icon>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        <div>
          <div className="contacts-names">
            {configContacts
              .filter(c => c.name)
              .map(({ brand, name, name2, outline, link, officeHours, phones, cellphones }, i) => (
                  <div key={i}>
                    <Row>
                      <Col xs="2" className="text-center">
                        <Icon>map-marker-alt</Icon>
                      </Col>
                      <Col>
                        <div className={`contacts-names-name ${name2 ? '' : 'mt'}`}>{name}</div>
                        <div className="contacts-names-name2">{name2}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2" className="text-center">
                        <Icon outline>clock</Icon>
                      </Col>
                      <Col>
                        <div className="contacts-names-name2">{officeHours}</div>
                      </Col>
                    </Row>
                    <Row className="contact-phones">
                      <Col xs="2" className="text-center">
                        <Icon>phone</Icon>
                      </Col>
                      <Col>            
                        {phones.map((p, i) => (
                          <span key={i}>
                            {i > 0 ? ' - ' : '' }
                            <a href={p.link} rel="noopener noreferrer" target="_blank" className="contact-phone">
                              {p.text}
                            </a>
                          </span>
                        ))}
                      </Col>
                    </Row>
                    <Row className="contact-phones">
                      <Col xs="2" className="text-center">
                        <Icon brand>whatsapp</Icon>
                      </Col>
                      <Col>
                        {cellphones.map((c, i) => (
                          <span key={i}>
                            {i > 0 ? ' - ' : '' }
                            <a href={c.link} rel="noopener noreferrer" target="_blank" className="contact-whatsapp">
                              {c.text}
                            </a>
                          </span>
                        ))}
                      </Col>
                    </Row>
                    <Divider min fixWidth />
                  </div>
              ))}
          </div>
          <Row>
            {configContacts.filter(c => !c.name).map(({ brand, icon, link }, i) => (
              <Col
                key={i}
                xs={extended ? "" : "auto"}
                className="text-center"
              >
                <a href={link} rel="noopener noreferrer" target="_blank" className={`contact-${icon}`}>
                  <Icon brand={brand}>{icon}</Icon>
                </a>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default Contacts;
