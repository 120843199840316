import React from "react";
import { Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Swiper from "react-id-swiper";

import { configName } from "base";

import { imageGet } from "../../../helpers";

import MasterPage from "../../MasterPage";
import Loading from "../../../components/Loading";
import Divider from "../../../components/Divider";
import Snackbar from "../../../components/Snackbar";

import "./style.css";

import imgPanal from "../../../../assets/img/nami/panal.svg";
import AutopartCarousel from "../AutopartCarousel";
import ProductStocks from "../../../components/ProductStocks";

const Images = (props) => {
  const { imageIndex, images, setImageIndex } = props;
  let swiper = null;
  return (
    <>
      <Swiper
        ref={(node) => {
          if (node) swiper = node.swiper;
        }}
        activeSlideKey={imageIndex.toString()}
        on={{ slideChange: () => setImageIndex(swiper.activeIndex) }}
      >
        {images.map(({ image }, i) => (
          <div
            key={i}
            className="autopart-detail-img"
            style={{ backgroundImage: `url(${image})` }}
          />
        ))}
      </Swiper>
      <div className="autopart-detail-img-tumbs">
        {images.map(({ image }, i) => (
          <div
            key={i}
            className={`autopart-detail-img-tumb ${
              imageIndex === i ? "active" : ""
            }`}
            style={{ backgroundImage: `url(${image})` }}
            onClick={() => setImageIndex(i)}
          />
        ))}
      </div>
    </>
  );
};

const Detail = (props) => {
  const { imageIndex, autopart, user, setImageIndex, cartAddItem } = props;
  const {
    isOriginal,
    isPublic,
    code,
    description,
    categoryName,
    images,
    products,
  } = autopart;
  const img = imageGet(code, images);
  const style = { backgroundImage: `url(${img})` };
  const isLoggedIn = !!user;
  const isSeller = user ? user.isSeller : false;
  const name =
    autopart &&
    autopart.applications &&
    autopart.applications[0].applicationName;
  const productStocks = products.map(({ productCode, productPrice }) => ({
    code: productCode,
    price: productPrice,
  }));

  return (
    <div className="autopart-detail card">
      <Row>
        <Col xs={12} lg={6}>
          {images && images.length > 1 ? (
            <Images
              imageIndex={imageIndex}
              images={images}
              setImageIndex={setImageIndex}
            />
          ) : (
            <div className="autopart-detail-img" style={style} />
          )}
        </Col>
        <Col xs={12} lg={6}>
          <div className="autopart-detail-right">
            {categoryName}
            <br />
            <span className="autopart-detail-title">{name}</span>
            <Divider marginTop />
            {isSeller && isPublic === false && (
              <div className="tag inactive">Inactivo</div>
            )}
            {isOriginal && <div className="tag original">Original</div>}
            <ProductStocks
              label="Stock"
              enableSale
              isLoggedIn={isLoggedIn}
              isSeller={isSeller}
              products={productStocks}
              cartAddItem={cartAddItem}
            />
          </div>
        </Col>
      </Row>
      {description && (
        <>
          <Divider marginTop />
          <Row>
            <Col xs={12}>
              <p>{description}</p>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

// const RelatedLinks = (props) => {
//   const {
//     isSeller,
//     autopartCode,
//     links,
//     modalRelatedLinksOpen,
//     toggle,
//     setCurrent,
//   } = props;

//   return (
//     <div className="autopart-detail-related-links">
//       <Divider />
//       <Container>
//         <Row noGutters>
//           <Col>
//             <div className="section-title">Links</div>
//           </Col>
//         </Row>
//         <Row noGutters>
//           <Col>
//             <div className="card list">
//               <Container>
//                 {links.map(({ name, url }, i) => {
//                   return (
//                     <a
//                       key={i}
//                       href={url}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       <Row>
//                         <Col>{name}</Col>
//                       </Row>
//                       <div />
//                     </a>
//                   );
//                 })}
//               </Container>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

const OriginalCodes = (props) => {
  const { originalCodes } = props;
  return (
    <div className="autopart-detail-list">
      <Container>
        <Row noGutters>
          <Col>
            <div className="section-title">Códigos originales</div>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <div className="card list not-hover">
              <Container>
                {originalCodes.map(({ code }, i) => {
                  return (
                    <Row key={i}>
                      <Col>{code}</Col>
                    </Row>
                  );
                })}
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Applications = (props) => {
  const { applications } = props;
  const hasYearRange = applications.some(
    (a) =>
      a.applicationYearRange !== "" &&
      a.applicationYearRange.toLowerCase() !== "n/d"
  );

  return (
    <div className="autopart-detail-list">
      <Container>
        <Row noGutters>
          <Col>
            <div className="section-title">Aplicaciones</div>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <div className="card list not-hover">
              <Container>
                {applications.map(
                  (
                    {
                      applicationName,
                      applicationYearRange,
                      applicationBrandName,
                    },
                    i
                  ) => {
                    return (
                      <Row key={i}>
                        <Col xs={3}>{applicationBrandName}</Col>
                        {hasYearRange && (
                          <Col xs={2}>{applicationYearRange}</Col>
                        )}
                        <Col xs={7}>{applicationName}</Col>
                      </Row>
                    );
                  }
                )}
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Dimensions = (props) => {
  const { height, width, system } = props;
  return (
    <div className="autopart-detail-dimensions">
      <Container>
        <Row noGutters>
          <Col>
            <div className="section-title">Medidas</div>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <div className="card">
              <Row
                noGutters
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Col xs="auto">
                  <img src={imgPanal} alt="Panal" />
                </Col>
                <Col xs="auto" className="h">
                  {height}
                </Col>
              </Row>
              <Row noGutters>
                <Col className="w text-center">{width}</Col>
              </Row>
              <Row noGutters>
                <Col className="s">Sistema: {system}</Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

class AutopartDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageIndex: 0,
      messages: [],
    };
  }

  cartAddItem = (product) => {
    const { autopart } = this.props;
    const name =
      (autopart &&
        autopart.applications.length &&
        autopart.applications[0].applicationName) ||
      "";
    this.props.cartAddItem({ ...product, imageCode: autopart.code, name });
    let { messages } = this.state;
    messages.push(<Snackbar type="cartItemSuccess" />);
    this.setState({ messages });
  };

  setImageIndex = (index) => this.setState({ imageIndex: index });

  componentDidMount() {
    document.title = configName;
  }

  render() {
    const {
      autopartIsLoading,
      relatedIsLoading,
      user,
      autopart,
      windowSize,
      setCurrent,
      related,
    } = this.props;

    const isSeller = user && user.isSeller;
    const { imageIndex, messages } = this.state;

    let height,
      width,
      system,
      relatedFiltered = [];

    if (autopart && autopart.products && autopart.products.length > 0) {
      const specs = autopart.products[0].specifications;
      height = specs.find((s) => s.specKey === "Alto");
      width = specs.find((s) => s.specKey === "Ancho");
      system = specs.find((s) => s.specKey == "Sistema");
      if (height) {
        height = height.specValue;
      }
      if (width) {
        width = width.specValue;
      }
      if (system) {
        system = system.specValue;
      }

      relatedFiltered = related.filter(
        ({ categoryName }) => categoryName !== autopart.products[0].categoryName
      );
    }

    const showOriginalCodes =
      autopart && autopart.originalCodes && autopart.originalCodes.length > 0;
    const showDimensions = height || width || system;
    const showApplications =
      autopart && autopart.applications && autopart.applications.length > 0;

    return (
      <MasterPage>
        <div className="autopart-detail">
          {autopartIsLoading ? (
            <Loading />
          ) : !autopart || !autopart.code ? (
            <Redirect to="/404" />
          ) : (
            <div>
              <Container>
                <Detail
                  isSeller={isSeller}
                  imageIndex={imageIndex}
                  autopart={autopart}
                  user={user}
                  cartAddItem={this.cartAddItem}
                  setCurrent={setCurrent}
                  setImageIndex={this.setImageIndex}
                />
              </Container>
              {(showOriginalCodes || showDimensions) && (
                <>
                  <Divider />
                  <Container>
                    <Row noGutters>
                      {showOriginalCodes && (
                        <Col xs={12} md={6}>
                          <OriginalCodes
                            originalCodes={autopart.originalCodes}
                          />
                        </Col>
                      )}
                      {showDimensions && (
                        <Col xs={12} md={6}>
                          <Dimensions
                            height={height}
                            width={width}
                            system={system}
                          />
                        </Col>
                      )}
                    </Row>
                  </Container>
                </>
              )}
              {showApplications && (
                <>
                  <Divider />
                  <Container>
                    <Row noGutters>
                      <Col>
                        <Applications applications={autopart.applications} />
                      </Col>
                    </Row>
                  </Container>
                </>
              )}
            </div>
          )}
          {!relatedIsLoading && relatedFiltered.length > 0 && (
            <AutopartCarousel
              windowSize={windowSize}
              items={[
                { title: "Tambien te puede interesar", items: relatedFiltered },
              ]}
            />
          )}
        </div>
        {messages.map((message, i) => {
          return <div key={i}>{message}</div>;
        })}
      </MasterPage>
    );
  }
}

export default AutopartDetailPage;
