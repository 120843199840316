import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MUISnackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { IconButton } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";

import Icon from "../../components/Icon";

//const size = sizeGet(window.screen.width);

const style = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  snackbar: {
    marginTop: 40,
    padding: "6px 14px 6px 24px",
    fontSize: window.size === "xs" ? "10px" : "12px",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 40px 0px rgba(0, 0, 0, 0.06), 0px 1px 18px 0px rgba(0, 0, 0, 0.04);"
  },
  icon: {
    width: 44,
    height: 44,
    fontSize: 14,
    borderRadius: "44px !important",
    color: "white"
  }
});

class Snackbar extends React.Component {
  state = { open: true };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { type, classes } = this.props;
    const { open } = this.state;

    let anchorOriginVertical = "";
    let anchorOriginHorizontal = "";
    let message = "";
    let variant = "";
    let autoHideDuration = null;

    switch (type) {
      case "cartItemSuccess":
        autoHideDuration = 1000;
        anchorOriginVertical = "top";
        anchorOriginHorizontal = "right";
        message = "El producto fue agregado correctamente!";
        variant = "success";
        break;
      case "warning":
        anchorOriginVertical = "top";
        anchorOriginHorizontal = "center";
        message = this.props.message;
        variant = "warning";
        break;
      case "error":
        anchorOriginVertical = "top";
        anchorOriginHorizontal = "center";
        message = this.props.message;
        variant = "error";
        break;
      default:
        anchorOriginVertical = "top";
        anchorOriginHorizontal = "center";
    }
    return (
      <MUISnackbar
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal
        }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={this.handleClose}
      >
        <SnackbarContent
          className={classNames(classes.snackbar, classes[variant])}
          message={message}
          action={[
            <IconButton
              key="close"
              className={classes.icon}
              onClick={this.handleClose}
            >
              <Icon>times</Icon>
            </IconButton>
          ]}
        />
      </MUISnackbar>
    );
  }
}

Snackbar.defaultProps = {
  isOpen: true,
  autoHideDuration: null,
  anchorOriginVertical: "top",
  anchorOriginHorizontal: "right",
  variant: "success"
};

export default withStyles(style)(Snackbar);
