import React from "react";
import { CRUDProduct } from "base";
import Render from "./render";

class ProductEdit extends React.Component {
  render() {
    return (
      <CRUDProduct>
        <Render {...this.props} />
      </CRUDProduct>
    );
  }
}
export default ProductEdit;
