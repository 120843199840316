import React from "react";
import { ProductCard as ProductCardBase } from "base";
import Render from "./render";

class ProductCard extends React.Component {
  render() {
    return (
      <ProductCardBase {...this.props}>
        <Render />
      </ProductCardBase>
    );
  }
}
export default ProductCard;
