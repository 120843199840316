// base
import {
  componentWithBase,
  requireAuth,
  OrdersPage as BaseOrdersPage,
  CartPage as BaseCartPage,
  ContactPage as BaseContactPage,
  ConfigPage as BaseConfigPage,
  ProductsPage as BaseProductsPage,
  ProductDetailPage as BaseProductDetailPage,
  UserActivationPage as BaseUserActivationPage,
  UserRegisterPage as BaseUserRegisterPage,
  UserLoginPage as BaseUserLoginPage
} from "base";
// views
import OrdersPage from "../views/OrdersPage";
import CartPage from "../views/CartPage";
import ContactPage from "../views/ContactPage";
import ConfigPage from "../views/ConfigPage";
import HomePage from "../views/HomePage";
import NotFoundPage from "../views/NotFoundPage";
import ProductsPage from "../views/ProductsPage";
import ProductDetailPage from "../views/ProductDetailPage";
import TestPage from "../views/TestPage";
import UserActivationPage from "../views/UserActivationPage";
import UserLoginPage from "../views/UserLoginPage";
import UserRegisterPage from "../views/UserRegisterPage";

export default [
  {
    exact: true,
    path: "/",
    component: HomePage
  },
  // Base
  {
    path: "/registro",
    component: componentWithBase(BaseUserRegisterPage, UserRegisterPage)
  },
  {
    path: "/iniciar",
    component: componentWithBase(BaseUserLoginPage, UserLoginPage)
  },
  {
    path: "/activar/:guid",
    component: componentWithBase(BaseUserActivationPage, UserActivationPage)
  },
  {
    exact: true,
    path: "/productos",
    component: componentWithBase(BaseProductsPage, ProductsPage)
  },
  {
    path: "/productos/:code",
    component: componentWithBase(BaseProductDetailPage, ProductDetailPage)
  },
  {
    path: "/mi-compra",
    component: componentWithBase(BaseCartPage, CartPage)
  },
  {
    path: "/contacto",
    component: componentWithBase(BaseContactPage, ContactPage)
  },
  // Auth
  {
    path: "/test",
    component: requireAuth(TestPage)
  },
  // Auth & Base
  {
    path: "/:companyId/pedidos",
    component: componentWithBase(BaseOrdersPage, requireAuth(OrdersPage))
  },
  {
    path: "/mis-compras",
    component: componentWithBase(BaseOrdersPage, requireAuth(OrdersPage))
  },
  {
    path: "/:companyId/config",
    component: componentWithBase(BaseConfigPage, requireAuth(ConfigPage))
  },
  // 404
  {
    path: "/404",
    component: NotFoundPage
  },
  {
    component: NotFoundPage
  }
];
