import React from "react";
import { CRUDImage } from "base";
import Render from "./render";

class ModalImageSelect extends React.Component {
  render() {
    return (
      <CRUDImage {...this.props}>
        <Render {...this.props} />
      </CRUDImage>
    );
  }
}
export default ModalImageSelect;
