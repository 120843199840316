import React from "react";
import { Link } from "react-router-dom";

import { configStyle } from "base";

import Header from "../../components/Header";
import Loading from "../../components/Loading";

import "./style.css";

class UserActivationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isLoading, isSuccess, activateError } = this.props;

    return (
      <div
        className="user-page user-page-activation"
        style={{
          background: `linear-gradient(0deg, transparent 50%, ${
            configStyle.primary.backgroundColor
          } 50%)`
        }}
      >
        <Header minimal transparent />
        <div className="user-page-content text-center">
          <h3>Activación</h3>
          <div className="card">
            {isLoading ? (
              <Loading />
            ) : (
              <div className="result text-center">
                {isSuccess ? (
                  <span>
                    Tu cuenta ha sido activada con éxito, ahora puedes{" "}
                    <Link to="/iniciar">ingresar</Link>
                  </span>
                ) : (
                  <span>{activateError}</span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UserActivationPage;
