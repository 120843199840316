import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import Loading from "../Loading";
import Icon from "../Icon";

const IconStock = props => {
  const { isLoading, available } = props;
  return (
    <div>
      {isLoading ? (
        <Loading min grey />
      ) : available === "" || available === "NO" ? (
        <Tooltip title="Sin stock">
          <div>
            <Icon>times</Icon>
          </div>
        </Tooltip>
      ) : available === "CO" ? (
        <Tooltip title="Ultimas unidades">
          <div>
            <Icon>exclamation</Icon>
          </div>
        </Tooltip>
      ) : available === "AV" ? (
        <Tooltip title="Disponible">
          <div>
            <Icon>check</Icon>
          </div>
        </Tooltip>
      ) : (
        <></>
      )}
    </div>
  );
};

export default IconStock;
