import React from "react";

import MasterPage from "../MasterPage";
import Banner from "../../components/Banner";

import "./style.css";

class TestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "Test";
  }

  render() {
    return (
      <MasterPage
        contentPaddingTop={false}
        headerProps={{ absolute: true, transparent: true }}
      >
        <Banner test />
      </MasterPage>
    );
  }
}

export default TestPage;
