import React from "react";
import { withRouter, Link } from "react-router-dom";
import queryString from "query-string";
import { configStyle } from "base";
import Button from "@material-ui/core/Button";
import Icon from "../Icon";
import "./styles.css";

class Pagination extends React.Component {
  render() {
    const { pag, rowsMax, total, location } = this.props;
    const current = parseInt(pag, 0);
    // pages
    let pages = [];
    let pageCount = Math.ceil(total / rowsMax);
    for (let i = 1; i <= pageCount; i++) pages.push(i);
    // pagination
    const { search } = location;
    const query = queryString.parse(search);
    // delete pag
    delete query["pag"];
    const and = Object.keys(query).length === 0 ? "" : "&";
    const link = `?${queryString.stringify(query)}${and}pag=`;
    return (
      pageCount > 1 && (
        <div className="pagination">
          {current > 1 && (
            <Link to={`${link}${current - 1}`}>
              <Button size="small">
                <Icon>chevron-left</Icon>
              </Button>
            </Link>
          )}
          {pages.map(i => {
            const active = current === i;
            return i === 1 ||
              (i <= current + 2 && i >= current - 2) ||
              i === pageCount - 1 ? (
              <Link key={i} to={`${link}${i}`}>
                <Button
                  size="small"
                  style={active ? configStyle.primary : {}}
                  disabled={active}
                >
                  {i}
                </Button>
              </Link>
            ) : null;
          })}
          {current < pageCount && (
            <Link to={`${link}${current + 1}`}>
              <Button size="small">
                <Icon>chevron-right</Icon>
              </Button>
            </Link>
          )}
        </div>
      )
    );
  }
}

Pagination.defaultProps = {
  location: ""
};

export default withRouter(Pagination);
