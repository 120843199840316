import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";

import Icon from "../Icon";
import Contacts from "../Contacts";
import Loading from "../Loading";

import "./style.css";

// MUI
const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: blue
  },
  typography: {
    useNextVariants: true
  }
});
const themeBtn = createMuiTheme({
  palette: {
    type: "dark",
    primary: blue
  },
  typography: {
    useNextVariants: true
  }
});

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      isSuscriptionLoading,
      isSuscriptionSuccess,
      // suscriptionError,
      email,
      errors,
      onInputChange,
      onSubmit
    } = this.props;

    return (
      <footer>
        <Container>
          <Row>
            <Col xs="12" sm="3" lg="3">
              <div className="links">
                <Link to="/contacto">Contacto</Link>
              </div>
            </Col>
            <Col xs="12" sm="9" lg="5">
              <div className="newsletter">
                <form onSubmit={onSubmit} noValidate>
                  <Row noGutters>
                    <Col>
                      <MuiThemeProvider theme={theme}>
                        <TextField
                          fullWidth
                          label="Newsletter"
                          name="email"
                          value={email}
                          error={Boolean(errors.email)}
                          disabled={isSuscriptionLoading}
                          onChange={onInputChange}
                          variant="outlined"
                          className="newsletter-input"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon outline>envelope</Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                        <span className="form-control-error">
                          {errors.email && errors.email}
                        </span>
                      </MuiThemeProvider>
                    </Col>
                    <Col xs="auto">
                      <MuiThemeProvider theme={themeBtn}>
                        <Button
                          type="submit"
                          variant="outlined"
                          className="newsletter-btn"
                          disabled={isSuscriptionLoading}
                        >
                          {isSuscriptionSuccess ? (
                            <span>
                              <Icon>check</Icon> Listo
                            </span>
                          ) : isSuscriptionLoading ? (
                            <Loading min grey />
                          ) : (
                            <span>Suscribirme</span>
                          )}
                        </Button>
                      </MuiThemeProvider>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
            <Col xs="12" md="12" lg="4">
              <Contacts />
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
