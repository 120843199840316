import React from "react";
import ProductCard from "../../components/ProductCard";
import Carousel from "../Carousel";

const ProductCarousel = ({ windowSize, items }) => {
  const mapItem = (item) => (
    <ProductCard
      hideActions={true}
      size={windowSize}
      product={item}
    />
  );

  return <Carousel items={items.map((item, i) => ({
    ...item,
    items: item.items.map(mapItem)
  }))} />
}

export default ProductCarousel;
