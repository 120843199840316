import React from "react";
import { Container, Row, Col } from "reactstrap";

import MasterPage from "../MasterPage";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from "../../components/ExpansionPanel";
import { Button, Switch } from "@material-ui/core";

import Loading from "../../components/Loading";

import ConfigBanner from "./ConfigBanner";

import "./style.css";
import Icon from "../../components/Icon";

const INITAL_MODAL = {
  modalOption: "",
  modalInputs: [],
  modalValues: {}
};

class ConfigPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITAL_MODAL,
      //
      expanded: null,
      //
      modalImageIsOpen: false,
      modalImageItemSelect: -1
    };
  }

  panelHandleChange = index => (event, expanded) => {
    const panel = `panel${index}`;
    this.setState({ expanded: expanded ? panel : false });
    if (expanded) this.props.load(index);
  };

  toggleProduction = () => {
    this.setState({ expanded: false });
    this.props.toggleProduction();
  };

  componentDidMount() {
    document.title = "Configuración";
    // this.props.load(0);
    // this.setState({ expanded: "panel0" });
  }

  render() {
    const { prod, configs, update, save } = this.props;
    const { expanded } = this.state;
    return (
      <MasterPage>
        <div className="config">
          <Container>
            <Row className="confg-actions justify-content-end">
              <Col xs="auto" className="switch">
                PRODUCCIÓN
                <Switch
                  color="primary"
                  className="switch-control"
                  checked={prod}
                  onChange={this.toggleProduction}
                  value={prod}
                />
              </Col>
              <Col xs="auto">
                <a href={"/test"} target="_blank" rel="noopener noreferrer">
                  <Button variant="outlined" color="primary" size="small">
                    Test <Icon>link</Icon>
                  </Button>
                </a>
              </Col>
            </Row>
            <Row>
              <Col>
                {configs.map(({ id, title, hasChanges, items }, i) => (
                  <ExpansionPanel
                    key={i}
                    expanded={expanded === `panel${i}`}
                    className="expansion-panel-panel"
                    onChange={this.panelHandleChange(i)}
                  >
                    <ExpansionPanelSummary className="expansion-panel-summary">
                      {title}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="expansion-panel-details">
                      {this.props[`${id}_isPending`] ? (
                        <div className="w-100 text-center">
                          <Loading />
                        </div>
                      ) : (
                        <div className="config-detail">
                          <Container>
                            <Row className="confg-actions justify-content-end">
                              <Col key={i} xs="auto">
                                <Button
                                  disabled={!hasChanges}
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={() => save(id)}
                                >
                                  Guardar
                                </Button>
                              </Col>
                              {!prod && (
                                <Col xs="auto">
                                  <Button
                                    disabled={hasChanges}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => save(id, true)}
                                  >
                                    Enviar a producción
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </Container>

                          <ConfigBanner
                            hasChanges={hasChanges}
                            id={id}
                            items={items}
                            update={update}
                          />
                        </div>
                      )}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </Col>
            </Row>
          </Container>
        </div>
      </MasterPage>
    );
  }
}

export default ConfigPage;
