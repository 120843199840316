import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import * as serviceWorker from "./serviceWorker";
// base
import { configRouter, configStyle, store, storageLoad, Global } from "base";
// routes
import routes from "./js/routes";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/fontawesome/css/all.css";
import "./assets/css/app.css";

// storage
storageLoad();

// MUI
const theme = createMuiTheme({
  // palette: {
  //   primary: blue
  // },
  palette: {
    primary: {
      main: configStyle.primary.backgroundColor
    }
  },
  typography: {
    useNextVariants: true
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Router basename={configRouter.baseName}>
        <Global>
          <Switch>
            {routes().map((prop, key) => {
              return (
                <Route
                  exact={prop.exact}
                  path={`${process.env.PUBLIC_URL}${prop.path}`}
                  key={key}
                  component={prop.component}
                />
              );
            })}
          </Switch>
        </Global>
      </Router>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
