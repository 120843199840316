import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import imgPrinter from "../../../assets/img/3d-print/printer.png";
import imgPrinting from "../../../assets/img/3d-print/printing.png";

import "./style.css";

const ThreeD = props => {
  return (
    <Container className="three-d text-center">
      <Row style={{ width: '100%' }}>
        <Col xs="12" md="6">
          <Link to="/productos?&c=1.2">
            <div className="three-d-item">
              <img src={imgPrinter} alt="Impresoras" />
              <br />
              Impresoras
            </div>
          </Link>
        </Col>
        <Col xs="12" md="6">
          <Link to="/3d/impresion">
            <div className="three-d-item">
              <img src={imgPrinting} alt="Impresión" />
              <div className="three-d-item">Impresión 3D</div>
            </div>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default ThreeD;
