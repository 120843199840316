import React from "react";
import { Container, Row, Col } from "reactstrap";
import { configContacts } from "base";
import Icon from "../Icon";

import "./style.css";

const Contacts = props => {
  const { extended } = props;
  return (
    <div className="contacts">
      {!extended ? (
        <Container>
          <Row>
            {configContacts.filter(c => !c.hideMin).map(({ brand, icon, link }, i) => (
              <Col key={i} className={`contact-${icon}`}>
                <a href={link} rel="noopener noreferrer" target="_blank">
                  <Icon brand={brand}>{icon}</Icon>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        <div>
          <div className="contacts-names">
            {configContacts
              .filter(c => c.name)
              .map(({ brand, name, name2, icon, outline, link }, i) => (
                <a
                  key={i}
                  href={link}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={`contact-${icon}`}
                >
                  <Row>
                    <Col xs="3" className="text-center">
                      <Icon brand={brand} outline={outline}>
                        {icon}
                      </Icon>
                    </Col>
                    <Col>
                      <span className={`contacts-names-name ${name2 ? '' : 'mt'}`}>{name}</span>
                      <br />
                      <span className="contacts-names-name2">{name2}</span>
                    </Col>
                  </Row>
                </a>
              ))}
          </div>
          <Row>
            {configContacts.filter(c => !c.name).map(({ brand, icon, link }, i) => (
              <Col
                key={i}
                xs={extended ? "" : "auto"}
                className={`text-center contact-${icon}`}
              >
                <a href={link} rel="noopener noreferrer" target="_blank">
                  <Icon brand={brand}>{icon}</Icon>
                </a>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default Contacts;
