import React from "react";
import { Container, Row, Col } from "reactstrap";
import Swiper from "react-id-swiper";

import "react-id-swiper/src/styles/css/swiper.css";
import "./style.css";

import MasterPage from "../MasterPage";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from "../../components/ExpansionPanel";

import imgBuild from "../../../assets/img/3d-print/building.png";
import imgDragon from "../../../assets/img/3d-print/dragon.png";
import imgGears from "../../../assets/img/3d-print/gears.png";
import imgGroot from "../../../assets/img/3d-print/groot.png";
import imgLamp from "../../../assets/img/3d-print/lamp.png";

import img3DPrinter from "../../../assets/img/3d-print/3d-printer.svg";
import imgDesign from "../../../assets/img/3d-print/design.svg";
import imgHand from "../../../assets/img/3d-print/hand.svg";

import SLAEx from "../../../assets/img/3d-print/sla-example.png";
import FDMEx from "../../../assets/img/3d-print/fdm-example.png";
import Divider from "../../components/Divider";

const images = [
  {
    name: "Dragon",
    src: imgDragon
  },
  {
    name: "Engranajes",
    src: imgGears
  },
  {
    name: "Arquitectura",
    src: imgBuild
  },
  {
    name: "Groot",
    src: imgGroot
  },
  {
    name: "Lamparas",
    src: imgLamp
  }
];

const swiperParms = {
  loop: true,
  centeredSlides: true,
  // effect: "fade",
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  }
  // pagination: {
  //   el: ".swiper-pagination",
  //   clickable: true,
  //   dynamicBullets: true
  // }
};

const questions = [
  {
    question: "¿Qué es una impresión 3D?",
    answer:
      "Es la creación de objetos reales a partir de un modelo tridimensional digital, por medio de la superposición de capas de cierto material."
  },

  {
    question: "¿Cómo funcionan las impresoras?",
    answer:
      "Las impresoras de plástico parten del material sólido presentado en forma de filamento, en un carrete de 1 kg. Una vez que la impresora llega a la temperatura adecuada, funde el material y lo coloca en forma de hilo una capa sobre otra, hasta completar las paredes externas de la pieza o rellenando la totalidad de su interior, según se desee. La impresora de resina parte del material líquido, que se solidifica por capas mediante un láser, lo que permite gran detalle y precisión."
  },

  {
    question: "¿Qué clase de piezas se puede imprimir?",
    answer:
      "Existe una gran variedad de posibilidades, desde repuestos costosos o difíciles de conseguir, piezas funcionales, diseños originales varios, como decoraciones y souvenirs, objetos personalizables e incluso volver tus ideas de diseño tangibles."
  },

  {
    question: "¿Qué necesito para imprimir una pieza?",
    answer:
      "Se requiere de un archivo que contenga el modelo 3D en formato STL, OBJ, PLY o Wrml 2.0 (se recomienda STL). En caso de no poseerlo, podemos diseñar el modelo 3D por usted."
  },

  {
    question: "¿Cómo llevan a cabo el archivo en caso de no poseerlo?",
    answer:
      "Nuestros diseñadores requerirán de las dimensiones en las que desees imprimir la pieza y una foto de referencia de la misma. En caso de ser la réplica de un objeto, se debe presentar la pieza en el local, para que se tomen las medidas exactas de la misma y así llevar a cabo un trabajo más exacto. Se le puede entregar el archivo creado, si así lo desea. El trabajo del diseñador tiene un costo adicional."
  },

  {
    question:
      "¿Cómo es la política de confidencialidad en cuanto a los archivos?",
    answer:
      "Cada archivo pertenece al cliente que lo realiza, nosotros no comercializamos los diseños. Sin embargo, permanece en nuestra base de datos, en caso de que desee volver a solicitar una pieza en el futuro."
  },

  {
    question: "¿En qué materiales se imprime y en que se diferencian?",
    answer:
      "Se imprime en plástico (PLA o ABS) y en resina. El PLA es el más empleado, biodegradable, no nocivo y se presenta en gran variedad de colores. El ABS es duro y resistente; se puede lijar, pulir, suavizar con acetona y pintar. La resina, de colores limitados,  ideal para piezas con gran detalle, ya que la impresión se realiza por medio de láser."
  },

  {
    question: "¿De qué tamaño máximo puede ser la pieza?",
    answer:
      "Esto dependerá de la impresora empleada y/o el material seleccionado. En plástico se permite hasta 20cm x 20cm x 15cm, 22cm x 14cm x 15cm y 30cm x 20cm x 57cm. En resina se permite hasta 14cm x 14cm x 15cm. En caso de que la pieza exceda las medidas presentadas, se puede imprimir la pieza por secciones, para más tarde unirlas. Se recomienda comunicarse con nuestros técnicos."
  },

  {
    question: "¿Cuál es el procedimiento para imprimir una pieza?",
    answer:
      "En primer lugar, se nos debe brindar el archivo con el modelo que desea imprimir o solicitar que nuestros diseñadores lo realicen (revisar “¿Qué necesito para imprimir una pieza?”). Obtenido esto, podremos cotizar la pieza e informar de un estimado de entrega. Una vez que usted sea informado y decida realizar la impresión, los diseñadores finalizarán el modelo 3D o se iniciará la impresión en caso de disponer del archivo"
  },

  {
    question: "¿Con qué anticipación debo realizar el encargo?",
    answer:
      "Recomendamos no dejarlo para último momento. Las impresiones suelen tardar horas e incluso días y siempre cabe la posibilidad de que se presenten fallas en el proceso, sin mencionar que debemos cumplir con diversos encargos de diferente complejidad."
  },

  {
    question: "¿Qué elementos influyen en la cotización?",
    answer:
      "El precio variará si usted aporta el archivo con el modelo 3D o si nuestros diseñadores deben realizarlo; además influye la complejidad de la pieza (tiempo de mano de obra). En cuento a la impresión, también afecta el material seleccionado y la cantidad empleada (tamaño de la pieza, si es hueca o maciza, etc)."
  }
];

const Images = props => {
  return (
    <Swiper {...swiperParms}>
      {images.map(({ name, src }, i) => (
        <img key={i} src={src} alt={name} />
      ))}
    </Swiper>
  );
};

class PrintPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: null };
  }

  panelHandleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  componentDidMount() {
    document.title = "Impresión 3D";
  }

  render() {
    const { expanded } = this.state;
    return (
      <MasterPage contentPaddingBottom={false}>
        <div className="print">
          <div className="section">
            <Container>
              <Row>
                <Col xs="12" md="6">
                  <Images />
                </Col>
                <Col xs="12" md="6">
                  <div className="service-message">
                    <h3>Servicio de impresión 3D</h3>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Divider marginTop marginBottom={false} />
          <div className="section service">
            <Container>
              <Row>
                <Col xs="12" md="4" className="text-center">
                  <img src={imgDesign} alt="Diseña tu idea" />
                  <br />
                  <h4>Diseña tu idea</h4>
                  <br />
                  Crea tu diseño en un programa 3D o preséntanos tu idea y
                  nosotros lo haremos por ti.
                </Col>
                <Col xs="12" md="4" className="text-center">
                  <img src={img3DPrinter} alt="Imprimimos tu diseño" />
                  <br />
                  <h4>Imprimimos tu diseño</h4>
                  <br />
                  Volvemos realidad tu diseño haciendo uso de diferentes
                  impresoras y materiales.
                </Col>
                <Col xs="12" md="4" className="text-center">
                  <img src={imgHand} alt="Disfruta de tu creación" />
                  <br />
                  <h4>Disfruta de tu creación</h4>
                  <br />
                  Disfruta de tu idea hecha realidad.
                </Col>
              </Row>
            </Container>
          </div>
          <Divider />
          <div className="section">
            <Container>
              <Row>
                <Col xs="12" className="text-center">
                  <h3 className="section-title">Tipos de impresión</h3>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="6" className="text-center">
                  <img src={FDMEx} alt="FDM" className="printer-type-img" />
                  <br />
                  <h4 className="section-title">FDM - Plástico</h4>
                  <div className="print-type">
                    Más económico, con gran variedad de colores, disponible en
                    dos tipos: <br />
                    El PLA es el más empleado, biodegradable, no nocivo y algo
                    flexible. <br />
                    El ABS es duro y resistente; se puede lijar, pulir, suavizar
                    con acetona y pintar, sin dañar la estructura.
                  </div>
                </Col>
                <Col xs="12" sm="6" className="text-center">
                  <img src={SLAEx} alt="SLA" className="printer-type-img" />
                  <br />
                  <h4 className="section-title">SLA - Resina</h4>
                  <div className="print-type">
                    Ideal para piezas pequeñas y/o de gran detalle, ya que la
                    impresión se realiza por medio de láser.
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Divider marginTop marginBottom={false} />
          <div className="section questions">
            <Container>
              <Row>
                <Col xs="12" className="text-center">
                  <h3 className="section-title">Preguntas frecuentes</h3>
                  <div className="questions-questions">
                    {questions.map(({ question, answer }, i) => (
                      <ExpansionPanel
                        key={i}
                        expanded={expanded === `panel${i}`}
                        className="expansion-panel-panel"
                        onChange={this.panelHandleChange(`panel${i}`)}
                      >
                        <ExpansionPanelSummary className="expansion-panel-summary">
                          {question}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="expansion-panel-details">
                          {answer}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    ))}
                    <ExpansionPanel
                      expanded={expanded === "panel-print-quality"}
                      onChange={this.panelHandleChange("panel-print-quality")}
                      className="expansion-panel-panel"
                    >
                      <ExpansionPanelSummary className="expansion-panel-summary">
                        Problemas relacionados con la calidad de impresión FDM
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className="expansion-panel-details">
                        Recomendamos este sitio que lista los errores mas
                        frecuentes y como solucionarlos:{" "}
                        <a
                          href="https://www.simplify3d.com/support/print-quality-troubleshooting/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Print Quality Troubleshooting Guide
                        </a>
                        .
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </MasterPage>
    );
  }
}

export default PrintPage;
