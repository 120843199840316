import React from "react";
import { Container, Row, Col } from "reactstrap";

import { numbersOnly } from "base";

import Icon from "../../components/Icon";

const ObjectSection = props => {
  const { edit, isList, padding, id, type, title, setCurrent, items } = props;
  return (
    <div
      className={`object-section ${edit ? "edit" : ""} ${padding ? "p" : ""}`}
    >
      {title && <span className="title">{title}</span>}
      <Container className="list">
        {items.map(
          ({ isDefault, disabled, fontSize, label, value, parms }, i) => {
            let renderVal, link;
            if (type === "PERSON_PHONE" || type === "COMPANY_PHONE")
              link = "tel:" + numbersOnly(value);

            switch (fontSize) {
              case "h2":
                renderVal = <h2 className="object-section-value">{value}</h2>;
                break;
              default:
                renderVal = (
                  <span className="object-section-value">{value}</span>
                );
                break;
            }
            return (
              <Row
                noGutters
                key={i}
                className={disabled ? "disabled" : ""}
                onClick={
                  disabled
                    ? null
                    : !edit
                    ? null
                    : () => setCurrent({ id, isList, isDefault, type, parms })
                }
              >
                {label && (
                  <Col xs="3">
                    <label>{label}</label>
                  </Col>
                )}
                <Col>
                  {link ? (
                    <a key={i} href={link}>
                      {renderVal}
                    </a>
                  ) : (
                    <span>{renderVal}</span>
                  )}
                </Col>
                {isDefault && (
                  <Col xs="1">
                    <div className="object-section-default">Defecto</div>
                  </Col>
                )}
              </Row>
            );
          }
        )}
        {edit && isList && (
          <Row
            noGutters
            onClick={
              !edit ? null : () => setCurrent({ id, isList, type, isAdd: true })
            }
          >
            <Col>
              <Icon>plus</Icon> Nuevo
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

ObjectSection.defaultProps = {
  padding: true
};

export default ObjectSection;
