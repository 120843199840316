import React from "react";
import Carousel from "../../../components/Carousel";
import AutopartCardMin from "../../../components/nami/AutopartCardMin";

const AutopartCarousel = ({ windowSize, isLoggedIn, isSeller, items }) => {
  const mapItem = (item) => (
    <AutopartCardMin
      size={windowSize}
      autopart={item}
      isLoggedIn={isLoggedIn}
      isSeller={isSeller}
      isLoading={false}
    />
  );

  return (
    <Carousel
      items={items.map((item, i) => ({
        ...item,
        items: item.items.map(mapItem),
      }))}
    />
  );
};

export default AutopartCarousel;
