import React from "react";
import Fab from "@material-ui/core/Fab";
import { GoTop as GoTopBase } from "base";
import Icon from "../Icon";
import "./style.css";

class GoTop extends React.Component {
  render() {
    return (
      <GoTopBase>
        <Fab size="small" color="primary">
          <Icon>arrow-up</Icon>
        </Fab>
      </GoTopBase>
    );
  }
}

export default GoTop;
