import React from "react";
import MasterPage from "../../MasterPage";
import { Container, Row, Col } from "reactstrap";

import "./style.css";

const SericesPage = () => {
  return (
    <MasterPage>
      <Container>
        <div className="services card">
          <h2>Radiadores</h2>
          <ul>
            <li>Reparación y limpieza</li>
            <li>Cambio de cajas plásticas</li>
            <li>Reparación de cajas plásticas</li>
            <li>Fabricación a medida</li>
            <li>Fabricación de radiadores de competición en aluminio</li>
            <li>Reparación de intercooler y radiadores de aluminio</li>
          </ul>

          <h2>Fabricación de panales y reparación</h2>
          <ul>
            <li>Calefacciónes</li>
            <li>Autos</li>
            <li>Camionetas</li>
            <li>Camiones</li>
            <li>Maquinas viales</li>
            <li>Grupo electrógeno</li>
            <li>Maquinas estacionarias</li>
            <li>Tractores</li>
            <li>Maquinaria agricola</li>
          </ul>

          <h2>Radiador de aceite</h2>
          <ul>
            <li>Reparación y limpieza (cobre, hierro y aluminio)</li>
            <li>Fabricación a medida</li>
          </ul>

          <h2>Aire acondicionado</h2>
          <ul>
            <li>Carga de gas c/fluo</li>
            <li>Chequeo integral del A/A</li>
            <li>Fabricación de caños de A/A</li>
            <li>Cambio de filtro de polen</li>
            <li>Colocación de equipos de A/A automotriz</li>
            <li>Colocación de equipos de frio</li>
            <li>Colocación de climatizadores</li>
            <li>Reparación de condensadores</li>
            <li>Reparación y adaptación de evaporadores</li>
            <li>Reparación de compresores</li>
          </ul>

          <h2>Sistema de enfriamiento</h2>
          <ul>
            <li>Chequeo de fugas</li>
            <li>Chequeo de temperatura</li>
            <li>Cambio de radiadores y calefacciones</li>
            <li>Fabricación a medida</li>
            <li>Limpieza de circuito (c/oxido o c/aceite)</li>
            <li>Cambio de liquido refrigerante</li>
          </ul>

        </div>
      </Container>
    </MasterPage>
  );
}

export default SericesPage;



