import React from "react";

const Icon = props => {
  const { brand, outline, children } = props;
  return (
    <i
      className={`fa${brand ? "b" : ""}${outline ? "r" : ""} fa-${children}`}
    />
  );
};
Icon.defaultProps = {
  brand: false,
  outline: false
};
export default Icon;
