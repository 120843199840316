import React from "react";
import { Row, Col } from "reactstrap";

import OrderCard from "../../components/OrderCard";
import Pagination from "../../components/Pagination";

import "./style.css";

const Orders = props => {
  const {
    readOnly,
    hidePagination,
    pag,
    rowsMax,
    resultCount,
    error,
    windowSize,
    orders,
    onChangeState
  } = props;

  return (
    <div>
      {error !== "" ? (
        <Row>
          <Col>
            <h5 className="orders-message text-center">{error}</h5>
          </Col>
        </Row>
      ) : (
        <Row>
          {parseInt(resultCount, 0) === 0 ? (
            <Col>
              <h5 className="orders-message text-center">
                No se encontro ningun resultado.
              </h5>
            </Col>
          ) : (
            <Col>
              {orders.map((order, i) => {
                return (
                  <OrderCard
                    key={i}
                    readOnly={readOnly}
                    windowSize={windowSize}
                    order={order}
                    onChangeState={onChangeState}
                  />
                );
              })}
            </Col>
          )}
        </Row>
      )}
      {!hidePagination && (
        <Row>
          <Col>
            <Pagination pag={pag} rowsMax={rowsMax} total={resultCount} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Orders;
