import React from "react";
import ProductCarousel from "../ProductCarousel";

class Relases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { windowSize, relases } = this.props;
    return (
      <ProductCarousel
        fullWidth={true}
        paddingLeft={true}
        windowSize={windowSize}
        items={relases}
      />
    );
  }
}

export default Relases;
