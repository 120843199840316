import React from "react";
import { Banner as BaseBanner } from "base";
import Render from "./render";

class Banner extends React.Component {
  render() {
    const { test } = this.props;
    return (
      <BaseBanner test={test}>
        <Render {...this.props} />
      </BaseBanner>
    );
  }
}
export default Banner;
