import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { IconButton, TextField, Button } from "@material-ui/core";

import { configStyle } from "base";

import Header from "../../components/Header";
import Snackbar from "../../components/Snackbar";
import Loading from "../../components/Loading";
import Icon from "../../components/Icon";

import "./style.css";

class UserLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: []
    };
  }

  addMessage = (type, message) => {
    let { messages } = this.state;
    messages.push(<Snackbar type={type} message={message} />);
    this.setState({ messages });
  };

  componentDidMount() {
    document.title = "Iniciar sesión";
  }

  render() {
    const {
      isLoading,
      email,
      password,
      errors,
      onInputChange,
      onSubmit
    } = this.props;
    const { messages } = this.state;

    return (
      <div
        className="user-page user-page-login"
        style={{
          background: `linear-gradient(0deg, transparent 50%, ${
            configStyle.primary.backgroundColor
          } 50%)`
        }}
      >
        <Header minimal transparent />
        <div className="user-page-content">
          <Container className="user-page-top">
            <Row>
              <Col xs="2">
                <IconButton onClick={() => window.history.back()}>
                  <Icon>arrow-left</Icon>
                </IconButton>
              </Col>
              <Col className="text-center">
                <h3>¡Bienvenido!</h3>
              </Col>
              <Col xs="2" />
            </Row>
          </Container>
          <div className="card">
            {isLoading ? (
              <Loading />
            ) : (
              <form noValidate onSubmit={onSubmit}>
                <TextField
                  fullWidth
                  error={errors.email}
                  type="email"
                  name="email"
                  className="form-control"
                  label="Email"
                  value={email}
                  onChange={onInputChange}
                />
                <span className="form-control-error">
                  {errors.email && errors.email}
                </span>
                <TextField
                  fullWidth
                  error={errors.password}
                  type="password"
                  name="password"
                  className="form-control"
                  label="Contraseña"
                  value={password}
                  onChange={onInputChange}
                />
                <span className="form-control-error">
                  {errors.password && errors.password}
                </span>
                <div className="login-actions">
                  <Button
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                  >
                    Entrar
                  </Button>
                </div>
              </form>
            )}
          </div>
          <div className="user-links text-left">
            <Link to="/restablecer-contrasena">Olvide mi contraseña</Link>
            <br />
            <Link to="/registro">Crear cuenta</Link>
          </div>
        </div>
        {messages.map((message, i) => {
          return <div key={i}>{message}</div>;
        })}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.loginWarning !== "" &&
      prevProps.loginWarning !== this.props.loginWarning
    ) {
      this.addMessage("warning", this.props.loginWarning);
    }

    if (
      this.props.loginError !== "" &&
      prevProps.loginError !== this.props.loginError
    ) {
      this.addMessage("error", this.props.loginError);
    }
  }
}

export default UserLoginPage;
