import React from "react";
import { AutopartRelases as BaseAutopartRelases } from "base";
import Render from "./render";

class AutopartRelases extends React.Component {
  render() {
    return (
      <BaseAutopartRelases>
        <Render />
      </BaseAutopartRelases>
    );
  }
}
export default AutopartRelases;
