import React from "react";
import AutopartCarousel from "../../../views/nami/AutopartCarousel";

class Relases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { windowSize, user, relases } = this.props;
    const isSeller = user ? user.isSeller : false;
    return (
      <AutopartCarousel
        fullWidth={true}
        paddingLeft={true}
        windowSize={windowSize}
        items={relases}
        isLoggedIn={!!user}
        isSeller={isSeller}
      />
    );
  }
}

export default Relases;
