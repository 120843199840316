import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { IconButton, TextField, Button } from "@material-ui/core";

import { configStyle } from "base";

import Header from "../../components/Header";
import Snackbar from "../../components/Snackbar";
import Loading from "../../components/Loading";
import Icon from "../../components/Icon";

import "./style.css";

class UserRestorePwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: []
    };
  }

  addMessage = (type, message) => {
    let { messages } = this.state;
    messages.push(<Snackbar type={type} message={message} />);
    this.setState({ messages });
  };

  componentDidMount() {
    document.title = "Restablecer contraseña";
  }

  render() {
    const {
      isLoading,
      isSuccess,
      step,
      email,
      newPassword,
      newPasswordConfirm,
      error,
      errors,
      onInputChange,
      onSubmit
    } = this.props;
    const { messages } = this.state;

    return (
      <div
        className="user-page user-page-reset-pwd"
        style={{
          background: `linear-gradient(0deg, transparent 50%, ${
            configStyle.primary.backgroundColor
          } 50%)`
        }}
      >
        <Header minimal transparent />
        <div className="user-page-content">
          <Container className="user-page-top">
            <Row>
              <Col xs="2">
                <IconButton onClick={() => window.history.back()}>
                  <Icon>arrow-left</Icon>
                </IconButton>
              </Col>
              <Col className="user-page-reset-pwd-title text-center">
                <h4>Restablecer contraseña</h4>
                <h5>Paso {step}</h5>
              </Col>
            </Row>
          </Container>
          <div className="card">
            {isLoading ? (
              <Loading />
            ) : isSuccess && !error ? (
              <div className="text-center">
                {step === 1 ? (
                  <h5>Compruebe su email para continuar con el proceso.</h5>
                ) : (
                  <h5>
                    Su contraseña se ha restablecido correctamente, ahora puedes{" "}
                    <Link to="/iniciar">iniciar</Link>
                  </h5>
                )}
              </div>
            ) : (
              <form noValidate onSubmit={onSubmit}>
                {step === 1 && (
                  <React.Fragment>
                    <TextField
                      fullWidth
                      error={Boolean(errors.email)}
                      type="email"
                      name="email"
                      className="form-control"
                      label="Email"
                      value={email}
                      onChange={onInputChange}
                    />
                    <span className="form-control-error">
                      {errors.email && errors.email}
                    </span>
                  </React.Fragment>
                )}
                {step === 2 && (
                  <React.Fragment>
                    <TextField
                      fullWidth
                      error={Boolean(errors.newPassword)}
                      type="password"
                      name="newPassword"
                      className="form-control"
                      label="Contraseña nueva"
                      value={newPassword}
                      onChange={onInputChange}
                    />
                    <span className="form-control-error">
                      {errors.newPassword && errors.newPassword}
                    </span>
                    <TextField
                      fullWidth
                      error={Boolean(errors.newPasswordConfirm)}
                      type="password"
                      name="newPasswordConfirm"
                      className="form-control"
                      label="Confirma la contraseña nueva"
                      value={newPasswordConfirm}
                      onChange={onInputChange}
                    />
                    <span className="form-control-error">
                      {errors.newPasswordConfirm && errors.newPasswordConfirm}
                    </span>
                  </React.Fragment>
                )}
                <div className="login-actions">
                  <Button
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                  >
                    {step === 1 ? "Enviar" : "Cambiar"}
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
        {messages.map((message, i) => {
          return <div key={i}>{message}</div>;
        })}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.error !== "" && prevProps.error !== this.props.error) {
      this.addMessage("error", this.props.error);
    }
  }
}

export default UserRestorePwd;
