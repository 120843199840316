import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

import { imageGet } from "../../../helpers";

import "./style.css";
import ProductStocks from "../../ProductStocks";

const Header = ({ code, categoryName, images, products }) => {
  const img = imageGet(code, images);
  let height, width, system;
  if (products.length > 0) {
    const specs = products[0].specifications;
    height = specs.find((s) => s.specKey === "Alto");
    width = specs.find((s) => s.specKey === "Ancho");
    system = specs.find((s) => s.specKey == "Sistema");
    if (height) {
      height = height.specValue;
    }
    if (width) {
      width = width.specValue;
    }
    if (system) {
      system = system.specValue;
    }
  }
  return (
    <Row noGutters>
      <Col xs="auto">
        <div
          className="autopart-card-img"
          style={{ backgroundImage: `url(${img})` }}
        />
      </Col>
      {(height || width || system) && (
        <>
          <Col />
          <Col>
            Alto:
            <br />
            {height}
          </Col>
          <Col>
            Ancho:
            <br />
            {width}
          </Col>
          <Col>
            Sistema:
            <br />
            {system}
          </Col>
        </>
      )}
      <Col className="text-right">
        <small>Categoria:</small>
        <br />
        <span className="autopart-category">{categoryName}</span>
      </Col>
    </Row>
  );
};

const Applications = ({ applications }) => {
  const applicationsMax = 5;
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => setShowAll(!showAll);
  const showMax = showAll ? applications.length + 1 : applicationsMax;
  const hasYearRange = applications.some(
    (a) =>
      a.applicationYearRange !== "" &&
      a.applicationYearRange.toLowerCase() !== "n/d"
  );

  return (
    <div className="autopart-applications">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Marca</TableCell>
            {hasYearRange && <TableCell>Año</TableCell>}
            <TableCell>Aplicación</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applications
            .slice(0, showMax)
            .map(
              (
                { applicationBrandName, applicationYearRange, applicationName },
                i
              ) => (
                <TableRow key={i}>
                  <TableCell>{applicationBrandName}</TableCell>
                  {hasYearRange && (
                    <TableCell>{applicationYearRange}</TableCell>
                  )}
                  <TableCell>{applicationName}</TableCell>
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
      {applications.length > applicationsMax && (
        <Button fullWidth onClick={toggleShowAll}>
          {showAll ? "Ver menos" : "Ver mas"}
        </Button>
      )}
    </div>
  );
};
const Actions = ({ code }) => (
  <div className="text-right">
    <Link to={`/productos/${code}`}>
      <Button variant="outlined" color="primary">
        Ver mas
      </Button>
    </Link>
  </div>
);

class AutopartCard extends React.Component {
  render() {
    const { isLoggedIn, isSeller, autopart } = this.props;

    const { categoryName, code, images, applications, products } = autopart;

    const productStocks = products.map(({ productCode, productPrice }) => ({
      code: productCode,
      price: productPrice,
    }));

    return (
      <div className="autopart-card">
        <div className="card">
          <Header
            code={code}
            categoryName={categoryName}
            images={images}
            products={products}
          />
          <Applications applications={applications} />
          <ProductStocks
            label="Stock"
            isLoggedIn={isLoggedIn}
            isSeller={isSeller}
            products={productStocks}
          />
          <Actions code={code} />
        </div>
      </div>
    );
  }
}

export default withRouter(AutopartCard);
