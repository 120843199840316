import React from "react";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import { Button, IconButton } from "@material-ui/core";
import Loading from "../Loading";
import Icon from "../../components/Icon";
import Actions from "../Actions";
import ModalConfirmRemove from "../ModalConfirmRemove";

import "./style.css";

class ModalImageSelect extends React.Component {
  new = () => {
    this.imageInput.click();
  };

  onChange = event => {
    const { target } = this.props;
    const file = event.target.files[0];
    const pointIndex = file.name.lastIndexOf(".");
    const name =
      pointIndex === -1 ? file.name : file.name.substring(0, pointIndex);
    let formData = new FormData();
    formData.append(name, file);

    const image = {
      ImageName: name,
      ImageTarget: target
    };

    this.props.add(image, formData);
  };

  render() {
    const {
      isAddLoading,
      isListLoading,
      isRemoveLoading,
      removeError,
      current,
      list: propList,
      onClose,
      onSelect,
      setCurrent,
      remove
    } = this.props;
    const list = [{ id: -1 }, ...propList];

    return (
      <Modal
        isOpen={true}
        fade={false}
        size="xl"
        className="modal-image-select card"
      >
        <div className="modal-top-title">Imagenes</div>
        <IconButton className="modal-top-close" onClick={onClose}>
          <Icon>times</Icon>
        </IconButton>
        <ModalBody>
          {isListLoading || isAddLoading ? (
            <Loading />
          ) : (
            <Row>
              {list.map((image, i) => (
                <Col key={i} xs="3 text-center">
                  {image.id === -1 ? (
                    <div>
                      <Button className="item new" onClick={() => this.new()}>
                        <Icon>plus</Icon>
                      </Button>
                      <input
                        ref={input => {
                          this.imageInput = input;
                        }}
                        type="file"
                        style={{ display: "none" }}
                        onChange={this.onChange}
                      />
                    </div>
                  ) : (
                    <div className="item">
                      <div className="actions-container">
                        <Actions
                          onRemove={() => {
                            setCurrent(image);
                          }}
                        />
                      </div>
                      <div
                        className="img"
                        style={{ backgroundImage: `url(${image.image})` }}
                        onClick={() => onSelect(image)}
                      />
                      {image.name}
                    </div>
                  )}
                </Col>
              ))}
            </Row>
          )}
        </ModalBody>
        {current && (
          <ModalConfirmRemove
            isLoading={isRemoveLoading}
            name={current.name}
            error={removeError}
            onCancel={() => setCurrent(null)}
            onConfirm={() => remove(current.id)}
          />
        )}
      </Modal>
    );
  }
}

export default ModalImageSelect;
