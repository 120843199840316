import React from "react";
import { Container, Row, Col } from "reactstrap";
import { IconButton } from "@material-ui/core";

import { configStyle, objects } from "base";

import Header from "../../components/Header";
import Divider from "../../components/Divider";
import Icon from "../../components/Icon";
import Loading from "../../components/Loading";
import ModalObjectEdit from "../../components/ModalObjectEdit";
import ObjectSection from "../../components/ObjectSection";

import "./style.css";

class UserProfilePage extends React.Component {
  componentDidMount() {
    document.title = "Perfil";
  }

  render() {
    const {
      isLoading,
      user,
      current,
      updateIsLoading,
      updateIsSuccess,
      updateError,
      update,
      setCurrent,
      onSuccess,
      clearError
    } = this.props;

    return (
      <div
        className="user-page user-page-profile"
        style={{
          background: `linear-gradient(0deg, transparent 50%, ${
            configStyle.primary.backgroundColor
          } 50%)`
        }}
      >
        <Header minimal transparent />
        <div className="user-page-content">
          <Container className="user-page-top">
            <Row>
              <Col xs="2">
                <IconButton onClick={() => window.history.back()}>
                  <Icon>arrow-left</Icon>
                </IconButton>
              </Col>
              <Col className="text-center">
                <h3>Perfil</h3>
              </Col>
              <Col xs="2" />
            </Row>
          </Container>
          <div className="card text-left">
            {isLoading || !user ? (
              <Loading />
            ) : (
              <div>
                <ObjectSection
                  edit
                  id={user.GUID}
                  type={objects.userPassword.type}
                  title="Usuario"
                  items={[
                    {
                      disabled: true,
                      label: "Email",
                      value: user.email
                    },
                    {
                      label: "Contraseña",
                      value: "••••••••"
                    }
                  ]}
                  setCurrent={setCurrent}
                />
                <Divider min marginBottom={false} />
                <ObjectSection
                  edit
                  id={user.GUID}
                  title="Datos personales"
                  type={objects.personName.type}
                  items={[
                    {
                      label: "Nombre",
                      value: `${user.person.name} ${user.person.lastName}`,
                      parms: user.person
                    }
                  ]}
                  setCurrent={setCurrent}
                />
                <Divider min marginBottom={false} />
                <ObjectSection
                  isList
                  edit
                  id={user.GUID}
                  type={objects.personPhone.type}
                  title="Teléfonos"
                  items={user.person.phones.map(phone => {
                    return {
                      isDefault: phone.isDefault,
                      label: "",
                      value: phone.phone,
                      parms: phone
                    };
                  })}
                  setCurrent={setCurrent}
                />
                <Divider min marginBottom={false} />
                <ObjectSection
                  edit
                  isList
                  id={user.GUID}
                  type={objects.personAddress.type}
                  title="Direcciones"
                  items={user.person.addresses.map(address => {
                    return {
                      isDefault: address.isDefault,
                      label: "",
                      value: `${address.address}, ${address.cityName}, ${
                        address.stateName
                      }`,
                      parms: address
                    };
                  })}
                  setCurrent={setCurrent}
                />
                {current !== null && (
                  <ModalObjectEdit
                    isOpen={current !== null}
                    isLoading={updateIsLoading}
                    isSuccess={updateIsSuccess}
                    isDefault={false}
                    error={updateError}
                    object={current}
                    onClose={() => setCurrent(null)}
                    onSuccess={onSuccess}
                    submitFunc={update}
                    clearError={clearError}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfilePage;
