import React from "react";

import { Chat, configSettings } from "base";

import Header from "../../components/Header";
import GoTop from "../../components/GoTop";
import Footer from "../../components/Footer";

import "./style.css";

class MasterPage extends React.Component {
  componentDidMount() {
    Chat();
  }

  render() {
    const {
      headerProps,
      contentPaddingTop,
      contentPaddingBottom,
      hideFooter,
      hideGoTop,
      children
    } = this.props;

    return (
      <div>
        <Header {...headerProps} />
        <div
          className={`master-content ${contentPaddingTop ? "pt" : ""} ${
            contentPaddingBottom ? "pb" : ""
          }`}
        >
          {children}
        </div>
        {!(configSettings.hideFooter || hideFooter) && <Footer />}
        {!hideGoTop && <GoTop />}
      </div>
    );
  }
}

MasterPage.defaultProps = {
  contentPaddingTop: true,
  contentPaddingBottom: true,
  hideFooter: false,
  hideGoTop: false,
};

export default MasterPage;
