import React from "react";
import { ProductStocks as BaseProductStocks } from "base";
import Render from "./render";

class ProductStocks extends React.Component {
  render() {
    return (
      <BaseProductStocks {...this.props}>
        <Render />
      </BaseProductStocks>
    );
  }
}
export default ProductStocks;
