import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import "./style.css";

// MUI
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#dc3545"
    }
  },
  typography: {
    useNextVariants: true
  }
});

const ModalConfirmRemove = props => {
  const { isLoading, rounded, name, error, onCancel, onConfirm } = props;
  return (
    <Modal
      isOpen={true}
      fade={false}
      className={`confirm-remove card ${rounded ? "rounded" : ""}`}
    >
      <ModalBody>
        <div className="text-center">
          {error !== "" ? (
            <span className="error">Error: {error}</span>
          ) : name ? (
            <span>
              ¿Esta seguro que desea eliminar <strong>{name}</strong>?
            </span>
          ) : (
            <span>¿Esta seguro que lo desea eliminar?</span>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="modal-footer-right">
          <Button disabled={isLoading} onClick={onCancel}>
            Cancelar
          </Button>
          <MuiThemeProvider theme={theme}>
            <Button disabled={isLoading} color="primary" onClick={onConfirm}>
              Si, Eliminar
            </Button>
          </MuiThemeProvider>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirmRemove;
