import React from "react";
import { Container, Row, Col } from "reactstrap";

import { configSettings } from "base";

import MasterPage from "../MasterPage";
import Loading from "../../components/Loading";
import Divider from "../../components/Divider";
import Snackbar from "../../components/Snackbar";
import Products from "../../components/Products";
import Search from "../../components/Search";
import Categories from "../../components/Categories";
import Brands from "../../components/Brands";
import Filters from "../../components/Filters";

import "./style.css";

class ProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };
  }

  cartAddItem = (product) => {
    this.props.cartAddItem(product);
    let { messages } = this.state;
    messages.push(<Snackbar type="cartItemSuccess" />);
    this.setState({ messages });
  };

  componentDidMount() {
    document.title = "Productos";
  }

  render() {
    const {
      windowSize,
      showProducts,
      brandsIsLoading,
      categoriesIsLoading,
      productsIsLoading,
      isAdd,
      pag,
      rowsMax,
      resultCount,
      current,
      history,
      brands,
      categories,
      products,
      classes,
      user,
      setCurrent,
      toggleAdd,
    } = this.props;
    const { messages } = this.state;
    const smartDevice = windowSize !== "lg" && windowSize !== "xl";

    return (
      <MasterPage contentPaddingTop={false}>
        {showProducts ? (
          productsIsLoading ? (
            <Loading />
          ) : (
            <div>
              <Search
                loadFromUrl
                paddingTop={true}
                history={history}
                link={`${process.env.PUBLIC_URL}/productos`}
              />
              <Divider min />
              <Container className="products-container">
                <Row>
                  <Col xs="8" lg="2">
                    <div className="d-none d-lg-block">
                      <span>{`${resultCount} resultados`}</span>
                      <Divider min marginTop />
                    </div>
                    <Filters loadFromUrl showOrderBy />
                  </Col>
                  <Col
                    xs="4"
                    className="d-block d-lg-none text-right result-count"
                  >
                    {`${resultCount} resultados`}
                  </Col>
                  <Col md="12" lg="10">
                    <Products
                      isAdd={isAdd}
                      pag={pag}
                      rowsMax={rowsMax}
                      windowSize={windowSize}
                      resultCount={resultCount}
                      current={current}
                      items={products}
                      classes={classes}
                      user={user}
                      cartAddItem={this.cartAddItem}
                      setCurrent={setCurrent}
                      toggleAdd={toggleAdd}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          )
        ) : (
          <div className="categories-brands">
            <div className="categories-brands-search">
              <Search loadFromUrl history={history} link={"/productos"} />
            </div>
            <Divider />
            <Container>
              {categoriesIsLoading ? (
                <Loading />
              ) : (
                <Categories windowSize={windowSize} categories={categories} />
              )}
            </Container>
            {!smartDevice && <Divider marginTop />}
            <Container>
              {!brandsIsLoading && <Brands brands={brands} />}
            </Container>
          </div>
        )}
        {messages.map((message, i) => {
          return <div key={i}>{message}</div>;
        })}
      </MasterPage>
    );
  }
}

export default ProductsPage;
