import React from "react";
import { Row, Col } from "reactstrap";

import { objects } from "base";

import ModalProductEdit from "../../components/ModalProductEdit";
import ProductCard from "../../components/ProductCard";
import Pagination from "../../components/Pagination";
import Icon from "../Icon";

const Products = props => {
  const {
    hidePrice,
    hideActions,
    hidePagination,
    isAdd,
    pag,
    rowsMax,
    resultCount,
    items,
    classes,
    user,
    current,
    cartAddItem,
    setCurrent,
    toggleAdd
  } = props;
  const isSeller = user ? user.isSeller : false;
  // console.log(current)
  return (
    <div>
      {isSeller && (
        <div className="product-admin-actions text-right">
          <button onClick={toggleAdd}>
            Agregar<Icon>plus</Icon>
          </button>
        </div>
      )}
      {parseInt(resultCount, 0) === 0 ? (
        <Row>
          <Col>
            <h5 className="products-message text-center">
              No se encontro ningun resultado.
            </h5>
          </Col>
        </Row>
      ) : (
        <Row>
          {items.map((item, i) => {
            return (
              <Col key={i} xs="12" sm="4">
                <ProductCard
                  hidePrice={hidePrice}
                  hideActions={hideActions}
                  isSeller={isSeller}
                  size="lg"
                  product={item}
                  cartAddItem={cartAddItem}
                  setCurrent={product =>
                    setCurrent({
                      type: objects.product.type,
                      id: product.code,
                      parms: {
                        ...product,
                        image:
                          product && product.images && product.images.length > 0
                            ? product.images[0]
                            : null
                      }
                    })
                  }
                />
              </Col>
            );
          })}
        </Row>
      )}
      {!hidePagination && (
        <Row>
          <Col>
            <Pagination
              pag={pag}
              rowsMax={rowsMax}
              total={resultCount}
              classes={classes}
            />
          </Col>
        </Row>
      )}
      <ModalProductEdit
        isAdd={isAdd}
        object={current}
        setCurrent={setCurrent}
        onSuccess={() => window.location.reload(true)}
      />
    </div>
  );
};

export default Products;
