import React from "react";
import { Filters as BaseFilters } from "base";
import Render from "./render";

class Filters extends React.Component {
  render() {
    return (
      <BaseFilters {...this.props}>
        <Render {...this.props} />
      </BaseFilters>
    );
  }
}
export default Filters;
