import React from "react";
import MasterPage from "../../MasterPage";
import { Container, Row, Col } from "reactstrap";
import { configLogo } from "base";

import imgElIman from "../../../../assets/img/nami/el-iman.svg";

import "./style.css";

const CompanyPage = () => {

  return (
    <MasterPage>
      <Container>
        <div className="company card">
          <Row>
            <Col>
              <h2>Historia</h2>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <p>
                Luis Panasco S.A. es una Empresa familiar, fundada hace más de
                50 años por don Luis D´Assuncao Panasco como fabricante de
                radiadores de automóvil para el mercado de reposición.
              </p>
            </Col>
            <Col xs={12} md={3}>
              <img src={imgElIman} alt="El Iman" />
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <p>
                En el año 1975 la Empresa desarrolla y produce su primer radiador como
                unidad original para la primer planta de Armado Automotriz Uruguaya,
                para un vehículo con motor General Motors. Posteriormente ha sido
                proveedora de distintas Marcas de vehículos cero kilómetro tales como:
                Fiat. Ford, General Motors, Renault, Citroen, Peugeot;&nbsp;exportando a
                Argentina durante los años 1977 1981 unidades para automóviles cero
                kilometro de las marcas Fiat y Renault.&nbsp;Durante 27 años nuestra
                Empresa ha acompañado a la Industria Automotriz ajustando nuestros
                procesos a sus muy exigentes normas de calidad
              </p>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <p>
                NAMI inició su actividad como distribuidores de Luis Panasco
                S.A., de sus productos “Radiadores EL IMAN”, por la necesidad de
                estar en contacto directo con nuestros clientes. Fue así que en
                1986 abrimos nuestro primer local en la calle Br. Artigas,
                ofreciendo el servicio para el sistema de enfriamiento y
                reparación de radiadores.
              </p>
            </Col>
            <Col xs={12} md={3}>
              <img src={configLogo.default} alt="Nami" />
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <p>
                Inmediatamente, atentos a las necesidades de nuestro mercado y a
                nuestra obligación para con nuestros clientes de ofrecerles un
                servicio cada vez más integral, anexamos otros servicios y productos
                en nuestros locales. Comenzando con productos importados como
                radiadores, intercoolers, condensadores, electroventiladores, etc.
                Siendo desde esa época representantes y distribuidres de las firmas
                Behr, Valeo, Visconde, Delphi, entre otras.
              </p>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <p>
                Años más tarde, abrimos nuestro segundo local en la calle Cerro Largo,
                orientado a la instalacion de equipos de aire acondicionado, con
                colocación en vehículos 0Km, así como tambien reparación y venta de
                todo tipo de repuestos para unidades usadas.
              </p>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <p>
                Como consecuencia de nuestra visitas, y viajes a ferias
                internacionales, vimos la necesidad de incluír en nuestros productos,
                la línea de bombas de aguas, viscosos, radiadores de aceite, y todo lo
                referente a los sistemas de enfriamiento y A/A automotrices y líneas
                pesadas. En una segunda etapa se incorporaron insumos de inyección
                electrónica y herramientas de diagnóstico.
              </p>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <p>
                Debido al gran crecimiento de estas últimas incorporaciones, nos vimos
                agregar un servicio de postventa especializado para la atencion de herramientas y equipamiento para el taller
                mecánico, como elevadores, scanners, balanceadoras, alienadoras, entre
                otras.
              </p>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <p>
                Luego de tantos años en el mercado, comprendemos que a nuestros
                clientes es importante brindarle un servicio integral, por tal motivo,
                si bien cada local se especifica en su tarea principal, ninguna
                abandonó la venta de los repuestos con la que comenzamos y crecimos.
              </p>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <p>
                Sin olvidar nuestros principios y orgullosos de nuestro crecimiento,
                mantenemos hasta el día de hoy los valores de nuestros orígenes como
                empresa familiar.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </MasterPage>
  );
}

export default CompanyPage;
